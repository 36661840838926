<template>
  <div id="footer" class="xr-area">
    <div class="">
      <p class="footer-content">
        {{content.footer1}}<br />
        {{content.footer2}}<br />
        {{content.footer3}}
      </p>
    </div>
    <p class="footer-copyright">
      {{content.copyRight}}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    content: Array,
  },
  name: "HomeBoxContent1",
  data() {
    return {};
  },
};
</script>

<style>
#footer .logo {
  text-align: center;
  margin-top: 108px;
  margin-bottom: 40px;
}
#footer .logo img {
  width: 125px;
  height: auto;
}

#footer .footer-content {
  color: #ffffff !important;
  font-family: 'Noto Sans TC' !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  text-align: center;
  line-height: 30px;
}
#footer .footer-copyright {
  font-size: 13px;
  color: #a1a6a7;
  display: block;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 20px;
}
@media (max-width: 768px) {
  .btn-m-p {
    text-align: center;
  }
}


#footer .about-content2 {
  color: #ffa940 !important;
  font-family: "Open Sans", Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
</style>
<style scoped>
</style>