<template>
    <div class="row justify-content-center">
        <div class="lang-btn">
            <div :class="['shape-ex5-block', {'is-lang': lang_tw}]" @click="getChangeLang('zh-tw')">中</div>
        </div>
        <div class="lang-btn bg-white">
            <div :class="['shape-ex5-block', {'is-lang': lang_en}]" @click="getChangeLang('en-us')">EN</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'LangSelect',
        data() {
            return {
                langs: ['繁體中文', 'English', '日本語'],
                lang_tw: true,
                lang_en: false,
            }
        },
        mounted() {
            let lang = this.$i18n.locale
            if (lang == "zh-tw") {
                this.lang_tw = true
                this.lang_en = false
            }
            if (lang == "en-us") {
                this.lang_tw = false
                this.lang_en = true
            }
        },
        methods: {
            getChangeLang(lang) {
                if (this.$route.params.language != undefined) {
                    let path = this.$route.path.split('/');
                    let path_str = `/${lang}`;
                    for (let i = 2; i < path.length; i++) {
                        path_str += `/${path[i]}`
                    }
                    this.$router.push(path_str)
                    window.location.reload()
                } else {
                    this.$router.push(`/${lang}` + this.$route.path)
                    window.location.reload()
                }

            }
        }
    }
</script>

<style>
    .lang-btn {
        width: 30px;
        height: 30px;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ffffff !important;
        margin: 0.2rem;
        cursor: pointer;
        font-size: 12px;
        transition: all .2s ease-in-out;
    }
    .lang-btn a{
        
        color:#000033;
    }
    .lang-btn:hover{
        transform: scale(1.1);
    }   
    .bg-white {
        background-color: #ffffff !important;
    }

    .is-lang {
        width: 30px;
        height: 30px;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ffff00 !important;
        cursor: pointer;
        font-size: 12px;
    }
</style>