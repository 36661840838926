<template>
  <div class="hot-button">
    <!-- <div :class="['email-btn', { 'is-active': isSticky }]">
      <a href="https://lin.ee/lL1unDi" target="_blank"
        ><b-icon class="icon-color" icon="chat-dots" aria-hidden="true"></b-icon
      ></a>
    </div> -->
    <div
      :class="['top-scroll-speed', { 'is-active': isSticky }]"
      @click="backToTop"
    >
      <b-icon class="icon-color" icon="arrow-up"></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "BacktopButton",
  create: function() {},
  data() {
    return {
      isSticky: true,
    };
  },

  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = false;
      } else {
        that.isSticky = true;
      }
    });
  },
  methods: {
    backToTop: function() {
      var scrollToTop = window.setInterval(function() {
        var pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 20);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 1);
    },
  },
};
</script>

<style scoped>
.hot-button {
  display: flex;
}
.email-btn {
  position: fixed;
  bottom: 90px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: #33ffff !important;
  /* background-color: #000066 !important; */
  transition: all 0.5s;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #000066;
}


.email-btn .icon-color {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #000066;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 30px;
}

.top-scroll-speed {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  /* background-color: #33ffff !important; */
  background-color: #000066 !important;
  transition: all 0.5s;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.top-scroll-speed .icon-color {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 30px;
}

.icon-color {
  color: #ffffff;
}

.top-scroll-speed:hover, .email-btn:hover{
  transition: 0.5s;
  transform: scale(1.1);
}

</style>
