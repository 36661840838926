<template>
    <div id="competition" class="xr-area">
        <div class="container">
            <div class="section-title xronly">
                <h1>{{ content.section2.title }}</h1>
            </div>
            <hr class="xrhr">
            <div class="row section-content text-center">
                <div class="col-lg-12">
                    <h2 class="statement">{{ content.section2.description1 }}</h2>
                </div>
                <div class="col-lg-12" style="margin:0">
                    <h3 class="statement" v-html="content.section2.description2"></h3>
                </div>
                <div class="col-lg-12">
                    <p class="p1" v-html="content.section2.description3"></p>
                </div>
                <div class="col-lg-12" style="margin:0">
                    <h3 class="statement" v-html="content.section2.description4"></h3>
                </div>
                <div class="col-lg-12">
                    <p class="p1 note" v-html="content.section2.description5"></p>
                </div>
                <!-- <div class="col-lg-3 text-center">
                    <p class="percent p1">{{ tweened1.toFixed(0) }}%</p>
                    <h3 class="mt-30">作品人機互動性</h3>
                    
                </div>
                <div class="col-lg-3 text-center">
                    <p class="percent p1">{{ tweened2.toFixed(0) }}%</p>
                    <h3 class="mt-30">創新應用發展性</h3>
                    
                </div>
                <div class="col-lg-3 text-center">
                    <p class="percent p1">{{ tweened3.toFixed(0) }}%</p>
                    <h3 class="mt-30">商業價值延展性</h3>
                    
                </div>
                <div class="col-lg-3 text-center">
                    <p class="percent p1">{{ tweened4.toFixed(0) }}%</p>
                    <h3 class="mt-30">作品演繹創意性</h3>
                    
                </div> -->
                <!-- <iframe class="mfp-iframe" src="//www.youtube.com/embed/qYmKSKUobYg?autoplay=1" frameborder="0" allowfullscreen=""></iframe> -->
                <iframe width="80%" height="480" src="https://www.youtube.com/embed/XLLfYWnwuKM"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                <iframe width="80%" height="480" src="https://www.youtube.com/embed/MZr-__xebu8"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
export default {
    props: {
        content: Array,
    },
    name: 'competition',
    data() {
        return {
            number1: 35,
            number2: 30,
            number3: 25,
            number4: 10,
            tweened1: 0,
            tweened2: 0,
            tweened3: 0,
            tweened4: 0,
            scrollY: 0
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted() {
    },
    methods: {
        change_bonus_number() {
            gsap.to(this, { duration: 2, tweened1: Number(this.number1) || 0 });
            gsap.to(this, { duration: 2, tweened2: Number(this.number2) || 0 });
            gsap.to(this, { duration: 2, tweened3: Number(this.number3) || 0 });
            gsap.to(this, { duration: 2, tweened4: Number(this.number4) || 0 });
        },
        handleScroll() {
            this.scrollY = window.scrollY;
            if (this.scrollY >= 1600) {
                this.change_bonus_number();
            }
        },
    },
}
</script>

<style scoped>
@media (max-width: 768px) {
    .btn-m-p {
        text-align: center;
    }
}
.note {
    font-size: 16px !important;
}
.statement {
    font-size: 18px;
    font-weight: 400;
    padding-top: 5px;
    line-height: 37px;
}

.percent {
    font-size: 48px !important;
    font-weight: 700 !important;
}

iframe {
    margin: 20px auto;

}</style>