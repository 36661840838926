<template>
  <div class="container-fluid" id="appContent">
    <div
      :class="['row', { 'flex-row-reverse': index % 2 == 0 }]"
      v-for="(item, index) in applicatinoList"
      :key="index"
      :id="item.id"
    >
      <div class="col-12 col-lg-6 p-0">
        <b-carousel
          id="carousel-fade"
          style="text-shadow: 0px 0px 2px #000"
          fade
          :controls="item.picture.length > 1"
        >
          <b-carousel-slide
            v-for="(itemP, indexP) in item.picture"
            :key="indexP"
          >
            <template #img>
              <img
                class="d-block img-fluid w-100"
                :src="require(`assets/project_images/pic/${itemP}`)"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div class="col-12 col-lg-6 p-0 carousel-content">
        <b-carousel
          id="carousel-1"
          :interval="0"
          :controls="item.slider.length > 1"
          indicators
          background="#ababab"
          style="text-shadow: 1px 1px 2px #333;"
        >
          <b-carousel-slide
            v-for="(itemJ, indexJ) in item.slider"
            :key="indexJ"
            :caption="itemJ.title"
            img-blank
            img-blank-color="#f0f2f3"
            img-alt="Blank image"
          >
            <p v-html="itemJ.content"></p>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationContent",
  mounted() {
    const that = this;
    const jsonFile = that.$route.params.jsonName;
    const language = this.$i18n.locale;
    that.getJsonData(jsonFile, language);
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      applicatinoList: [],
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    getJsonData(jsonFile, language = "zh-tw") {
      let json = require(`./Content/${jsonFile}.json`);
      this.applicatinoList = json[language];
    },
  },
};
</script>

<style lang="scss">
#appContent .carousel-item img {
  height: 600px;
  object-fit: cover;
}

.carousel-content {
  background-color: #f0f2f3;

  .carousel-indicators li {
    background-color: #3e0c0c;
    opacity: 0.3;
  }

  .carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    text-shadow: none;
    color: #3e0c0c;
  }

  .carousel-caption h3 {
    padding-bottom: 10px;
  }

  .carousel-caption p {
    text-align: left;
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: #000;
    border-radius: 99em;
    padding: 20px 20px;
    background-size: 20px;
    background-position: center;
  }

}



@media only screen and (max-width: 998px) {
  .carousel-item img {
    height: auto;
  }
  #carousel-1 .carousel-item img {
    /*height: 700px;*/
  }
}
</style>
