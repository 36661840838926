<template>
    <div class="bg-color">
        <Navbar />
        <Header :bannerTitle="bannerTitle[this.$route.params.jsonName]" :breadCrumb="bannerTitle[this.$route.params.jsonName]" />
        <ApplicationNav />
        <ApplicationContent />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/Layout/NavbarStyleTwo'
    import Header from 'components/DefaultHome/KECC5G/Common/Header'
    import FooterTop from 'components/DefaultHome/Layout/FooterTop'
    import FooterBottom from 'components/DefaultHome/Layout/BacktopButton'
    import ApplicationNav from './ApplicationNav'
    import ApplicationContent from './ApplicationContent'
    export default {
        name: 'ApplicationMain',
        metaInfo() {
            return {
                title: this.bannerTitle[this.$route.params.jsonName],
            }
        },
        components: {
            Navbar,
            Header,
            ApplicationNav,
            ApplicationContent,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: { "smart_industry" : this.$t('smart_industry.title') , "service" : this.$t('application.title'), "faq" : "FAQ"}
            }
        },
        mounted(){
            //console.log(this.$router.currentRoute.path)
            //this.bannerTitle
        }
    }
</script>

<style>
    .bg-color {
        background-color: #eff4f5;
    }
</style>