<template>
  <div id="news" class="section-width pt-35 ">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-auto col-sm-8 col-12">
          <div class="section-title">
            <h2>{{ $t("navbar.menu_7") }}</h2>
          </div>
          <div
            class="wow fadeIn pb-30 news-box"
            v-for="(item, index) in newsGroupTitleList"
            :key="index"
          >
            <div class="row animated fadeInUp">
              <div class="col-md-2 col-sm-2 news-date">
                <div>
                  {{ item.post_date }}
                </div>
              </div>
              <div class="col-md-10 col-sm-10 news-title">
                <router-link
                  :to="{ path: '/news/post/' + item.announcement_message_id }"
                  class="link"
                >
                  <img v-if="item.topping == 1" />
                  <a>{{ item.message_title }}</a>
                </router-link>
              </div>
            </div>
          </div>
          <div :class="['row', 'text-center']" v-show="isShowNextPage">
            <div class="col-lg-12 read-more">
              <button class="blue-btn" @click="getNewsGroupList(selectGroupId)">
                {{ $t("pageBtn") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";

export default {
  name: "BlogDetails",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/announcement/`,
      newsGroupTitleList: [],
      page: 1,
      isShowNextPage: true,
    };
  },
  mounted() {
    this.getNewsGroupList();
  },
  methods: {
    getNewsGroupList(group_id = 3) {
      let limit = 10;
      this.page = this.selectGroupId != group_id ? 1 : this.page + 1;
      let api = `${this.apiUrl}message_list?announcement_id=5&language_id=${this.$i18n.locale}&auto_replace=false&announcement_group_id=${group_id}&limit=${limit}&page=${this.page}&filters[is_hidden]=0`;
      this.axios.get(api).then((response) => {
        let data = response.data["list"];
        for (let i = 0; i < data.length; i++) {
          data[i].post_date = DateFormat(
            new Date(Date.parse(data[i].post_date.replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );
        }
        this.isShowNextPage = limit * this.page < response.data.count.all;
        if (this.selectGroupId == group_id) {
          this.newsGroupTitleList = this.newsGroupTitleList.concat(data);
        } else {
          this.newsGroupTitleList = data;
        }
        this.selectGroupId = group_id;
      });
    },
  },
};
</script>

<style scoped>
.section-title {
  margin: -12px auto 60px;
}

.section-title h2 {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  color: #000066;
  margin-bottom: 20px;
  margin-top: 30px;
}
.link:not(:hover) {
  color: #000000;
}
.news-box {
  margin-bottom: 10px;
}
.read-more {
  margin-top: 35px;
  margin-bottom: 100px;
}
</style>
<style>
figure.media {
  display: block;
}

.pt-35 {
  padding-top: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}
.news-date {
  color: #000066;
}
</style>
<style lang="css" src="assets/css/ckeditor.css"></style>
