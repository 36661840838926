<template>
  <div>
    <div id="master" class="xr-area">
      <div class="container">
        <div class="section-title xronly">
          <h1>{{content.section4.title}}</h1>
        </div>
        <hr class="xrhr" />
        <div class="row section-content">


          <div class="col-lg-4  wow fadeInUp">
            <div class="tic tic1">
              <img src="~assets/project_images/xr/05_speaker1.png" class="topic_img" />
              <h3>{{content.section4.imageTitle1}}</h3>
              <p class="p1">{{content.section4.imageDescription1_1}}</p>
              <hr class="mf-hr">
              <p class="p1">{{content.section4.imageDescription1_2}}</p>
            </div>
          </div>
          <div class="col-lg-4 text-center wow fadeInUp">
            <div>
            <img src="~assets/project_images/xr/05_speaker2.png" class="topic_img" />

            <h3>{{content.section4.imageTitle2}}</h3>
            <p class="p1">{{content.section4.imageDescription2_1}}</p>
            <hr class="mf-hr">
            <p class="p1">{{content.section4.imageDescription2_2}}</p>
          </div>
          </div>
          <div class="col-lg-4 wow fadeInUp">
            <div class="tic tic3">
              <img src="~assets/project_images/xr/05_speaker3.png" class="topic_img" />

              <h3>{{content.section4.imageTitle3}}</h3>
              <p class="p1">{{content.section4.imageDescription3_1}}</p>
              <hr class="mf-hr">
              <p class="p1">{{content.section4.imageDescription3_2}}</p>
            </div>
          </div>
          <div class="col-lg-4  wow fadeInUp">
            <div class="tic tic1" >
              <img src="~assets/project_images/xr/05_speaker4.png" class="topic_img" />

              <h3>{{content.section4.imageTitle4}}</h3>
              <p class="p1">{{content.section4.imageDescription4_1}}</p>
              <hr class="mf-hr">
              <p class="p1">{{content.section4.imageDescription4_2}}</p>
            </div>
          </div>
          <div class="col-lg-4 text-center wow fadeInUp">
            <div>
            <img src="~assets/project_images/xr/05_speaker5.png" class="topic_img" />

            <h3>{{content.section4.imageTitle5}}</h3>
            <p class="p1">{{content.section4.imageDescription5_1}}</p>
            <hr class="mf-hr">
            <p class="p1">{{content.section4.imageDescription5_2}}</p>
          </div>
          </div>

        </div>
        <div id="mf_sign">
        <button class="sign_up" @click="openNewPage">{{content.btn}}</button>
      </div>
      </div>
      
    </div>

    <div id="food" class="xr-area">
      <div class="container">
        <div class="section-title xronly">
          <h1>{{content.section5.title}}</h1>
        </div>
        <hr class="xrhr foodhr" />
        <h2>{{content.section5.description}}</h2>
          <h2>{{content.section5.description2}}</h2>
        <div class="row section-content" id="food_content">
          

          <div class="col-lg-4  wow fadeInUp">
            <div class="tic tic1" id="">
              <img src="~assets/project_images/xr/06_food1.png" class="topic_img" />

              <h3>{{content.section5.imageDescription1}}</h3>
            </div>
          </div>
          <div class="col-lg-4 text-center wow fadeInUp">
            <div>
            <img src="~assets/project_images/xr/06_food2.png" class="topic_img" />

            <h3>{{content.section5.imageDescription2}}</h3>
          </div>
          </div>
          <div class="col-lg-4 wow fadeInUp">
            <div class="tic tic3">
              <img src="~assets/project_images/xr/06_food3.png" class="topic_img" />

              <h3>{{content.section5.imageDescription3}}</h3>
            </div>
          </div>
          <div class="col-lg-4  wow fadeInUp">
            <div class="tic tic1">
              <img src="~assets/project_images/xr/06_food4.png" class="topic_img" />

              <h3>{{content.section5.imageDescription4}}</h3>
            </div>
          </div>
          <div class="col-lg-4 text-center wow fadeInUp">
            <div>
            <img src="~assets/project_images/xr/06_food5.png" class="topic_img" />

            <h3>{{content.section5.imageDescription5}}</h3>
          </div>
          </div>
          <div class="col-lg-4 text-center wow fadeInUp">
            <div class="tic tic3">
            <img src="~assets/project_images/xr/06_food6.png" class="topic_img" />

            <h3>{{content.section5.imageDescription6}}</h3>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Array,
  },
  name: "topic",
  
  mounted() {
  
  },
  methods: {
    openNewPage() {
      window.open('https://kecc.kktix.cc/events/76131a63-copy-1', '_blank')
    }
  }
};
</script>

<style scoped>
.foodhr {
  margin-bottom: 35px;
}
.col-lg-4 {
  margin: 0 0 0 0;
}
.xr-area {
  margin-top: 140px;
}

.topic_img {
  width: 300px;
  height: 300px;
}
#food_content {
  margin-top: 45px;
}
#masterAndFood {
  padding-top: 10px !important;
}

#topic img {
  margin-bottom: 40px;
}

.tic {
  width: 300px;
  text-align: center;
}

.tic1 {
  float: left;
  text-align: center;
}

.tic3 {
  float: right;
  text-align: center;
}

#t-conteant .p1,
#t-conteant .p2,
#t-conteant .p3 {
  /* line-height: 40px; */
  /* font-size: 16px; */
}

.p1,
.p2,
.p3 {
  margin-bottom: 0 !important;
}
.mf-hr {
    border: 1px solid #EE81A4;
        width: 30%;
        margin-bottom: 2px !important;
        margin-top: 4px;
        
}
#master h3 {
  margin-top: 33px;
  margin-bottom: 20px;
}
#mf_sign {
  text-align: center;
  margin-top: 5px;
}
#food h3 {
  margin: 40px 0 10px;
}
@media only screen and (max-width: 991px) {
  .tic1 {
    float: none;
    width: auto;
  }

.tic3 {
    float: none;
    width: auto;
  }

  #t-conteant .p1,
  #t-conteant .p2,
  #t-conteant .p3 {
    line-height: unset;
  }
}

@media only screen and (min-width:768px) and (max-width: 991px) {
  .xr-area {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .xr-area {
    margin-top: 140px;
  }
  #food h3 {
  margin: 40px 0 40px;
}
}
</style>