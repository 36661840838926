<template>
    <div id="organizer" class="xr-area">
        <div class="container">
            <div class="section-title xronly">
                <h1 id="organizer-title">{{content.section6.title}}</h1>
            </div>
            <hr class="xrhr">
            <div class="row section-content">
                
                <div class="col-lg-12 text-center">
                    <img src="~assets/project_images/xr/07_logo_1_kecc_white.svg" class="topic_img">
                    

                    <img src="~assets/project_images/xr/07_logo_2_compal_white.svg" class="topic_img">
                    

                    <img src="~assets/project_images/xr/07_logo_3_light_white.svg" class="topic_img">
                    

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    content: Array,
  },
    name: 'organizer',
    data() {
        return {

        }
    },

}
</script>

<style scoped>
@media (max-width: 768px) {
    .btn-m-p {
        text-align: center;
    }
}
#organizer-title {
    margin-top: 106px;
}
#organizer img {
    margin: 13px 38px;
}
#organizer {
    margin-bottom: 90px;
}
@media (max-width: 442px) {
    #organizer img[data-v-710d8564] {
    margin: 20px 38px;
}
}
</style>