<template>
  <div class="blog-details-area pb-100 pt-40">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-8 col-12 listbox pb-20">
          <div :class="['postlist ', { 'is-exhibition': postID == exhibitionData.blog_post_id }]"
            v-for="exhibitionData in exhibitionList" :key="exhibitionData.blog_post_id">
            <!-- <router-link :to="{ path: '/exhibition/' + exhibitionData.blog_post_id }" class="more">
              <h3>{{ exhibitionData.post_title }}</h3>
            </router-link> -->
            <p class="more" @click="getPostData(exhibitionData.blog_post_id)">{{ exhibitionData.post_title }}</p>
            
          </div>
        </div>

        <div class="col-sm-auto col-sm-8 col-12">
          <div class="blog-content-column">
            <div class="blog-content-area post-items-style2">
              <div class="post-title">
                <h3>
                  {{ postData.post_title }}
                </h3>

              </div>
              <div class="post-content ck-content">
                <figure>
                  <figcaption class="aboutus-introduction-text" v-html="postData.post_content"></figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";

export default {
  name: "BlogDetails",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/blog/post`,
      listApiUrl: `${process.env.VUE_APP_API_URL}/blog/post_list`,
      exhibitionList: "",
      postData: [],
      windowHref: "",
      postID: this.$route.params.post_id,
      currentURL: window.location.href,
      message: window.location.href
    };
  },
  mounted() {
    this.getExhibitionListData();
    this.windowHref = window.location.href;
  },
  methods: {
    getExhibitionListData() {
      const api = this.listApiUrl;
      let exhibition = {
        blog_id: 5,
        page: 1,
        limit: 999,
        order_by: [{ field: "post_date", method: "DESC" }],
        language_id: this.$i18n.locale,
        filters: {
          tags: [],
        },
        image_width: 510,
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json"
        },
      }
      this.axios.post(api, exhibition, postConfig).then((result) => {
        for (let i = 0; i < result.data.list.length; i++) {
          result.data.list[i].post_date = DateFormat(
            new Date(Date.parse(result.data.list[i].post_date.replace(/-/g, "/"))),
            "yyyy/mm/dd"
          );
        }
        this.exhibitionList = result.data.list;

        if (this.postID == undefined) {
          this.postID = result.data.list[0].blog_post_id;
        }
        this.getPostData();
      });
    },
    async getPostData(blog_post_id = this.postID) {
      this.postID = blog_post_id;
      
      const api = this.apiUrl + `?blog_post_id=${blog_post_id}&language_id=0`;
      let locale = 'zh-TW';
      if (this.$i18n.locale == 'en-us') {
        locale = 'en-US';
      }
      await this.axios.get(api).then((result) => {
        //將zh-TW去掉，不然上面直接使用['zh-TW']會噴錯
        result.data.post_content = result.data.post_content[locale];
        result.data.post_introduction = result.data.post_introduction[locale];
        result.data.post_title = result.data.post_title[locale];
        result.data.post_date = DateFormat(
          new Date(Date.parse(result.data.post_date.replace(/-/g, "/"))),
          "yyyy/mm/dd"
        );
        this.postData = result.data;
      });
    },
  },
  watch: {
    '$route': function () {
      this.postID = this.$route.params.post_id,
        this.getPostData();
    }
  }
};
</script>

<style scoped>
.blog-details-area .blog-details-desc .article-footer .article-share .social li a {
  width: 35px;
  height: 35px;
}

.blog-details-area {
  color: #000066;
  font-size: 14px;
}



/* .blog-details-area {
  color: #000033;
} */


.blog-details-area .blog-details-desc .article-footer {
  margin-top: 15px;
}



.listbox {
  text-align: left;
}
.postlist {
  display: inline-block;
  border: 1px #000066 solid;
  width: 167px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;
  vertical-align: middle;
  height: 46px;
  cursor: pointer;
}
.postlist:hover {
    background-color: #000066;
}
.postlist:hover p{
    color: #fff;
}

.postlist p {
  font-size: 16px;
  font-family: 'Noto Sans TC';
font-style: normal;
font-weight: 500;
margin-top: 9px;
display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 3px;
  
}

.is-exhibition {
  background-color: #000066;

}

.is-exhibition p {
  color: #ffff00;
}

.post-title {
  margin-bottom: 20px;
}

.post-title h3 {
  font-family: 'Noto Sans TC';
font-style: normal;
font-weight: bold;
font-size: 25px;
/* or 160% */

text-align: justify;
letter-spacing: 0.11em;

color: #212121;

}



/*
@media screen and (max-width: 365px) {
  .postlist:nth-child(1n+1) {
    margin-right: 0;
  }
}
*/

@media screen and (min-width: 1200px) {
  .postlist:nth-child(4n+4) {
    margin-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .postlist {
      width: 205px;
  }
}

@media screen and (max-width: 768px) {
  .postlist {
      width: 45.3%;
  }
  .blog-details-area .container, 
.blog-details-area.container-sm {
    max-width: 700px;
}
}



@media screen and (max-width: 1200px) and (min-width: 990px) {
  .postlist:nth-child(3n+3) {
    margin-right: 0;
  }
}
@media screen and (max-width: 990px) and (min-width: 345px) {
  .postlist:nth-child(2n+2) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 345px) {
  .postlist {
    margin-right: 0;
  }
}

</style>
<style>
figure.media {
  display: block;
}

.pt-40 {
  padding-top: 40px;
}
.pb-20 {
  padding-bottom: 20px;
}
.more {
  vertical-align: middle;
}
</style>
<style lang="css" src="assets/css/ckeditor.css">

</style>