<template>
    <div id="smart-venue" class="faq-area">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('about.title') }}</h2>
            </div>
            <div class="row section-content " :class="[{'text-center': language == 'en-us'}]">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <p>{{ $t('about.content') }}</p>
                    <!--<a href="#" class="default-btn mt-30">{{ $t('about.more_button') }}</a>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HomeBoxContent1',
        data() {
            return {
                language:this.$i18n.locale
            }
        },

    }
</script>

<style>
    .faq-area{
        /*background-color: #ffff00;*/
        margin-top: 65px;
    }
    .faq-area .container {
        max-width: 1000px;
    }
    @media (max-width: 768px) {
        .btn-m-p {
            text-align: center;
        }
    }

</style>