<template>
    <header class="header-area fixed-top">
        <div class="nav-area">
            <div :class="['navbar-area', {'is-sticky': isSticky}]">
                <div class="main-nav">
                    <nav class="navbar navbar-expand-lg">
                        <div class="container-fluid">
                            <a class="navbar-brand" :href="this.$i18n.bindLang('/')">
                                <div>
                                    <img class="nav-logo-image" src="~assets/project_images/svg/5g-logo.svg"
                                        alt="image">
                                </div>
                            </a>

                            <b-navbar-toggle target="navbarSupportedContent">
                                <span class="icon-bar top-bar"></span>
                                <span class="icon-bar middle-bar"></span>
                                <span class="icon-bar bottom-bar"></span>
                            </b-navbar-toggle>

                            <b-collapse v-model="show" class="collapse navbar-collapse mean-menu text-center justify-content-end"
                                id="navbarSupportedContent" is-nav>

                                <ul class="navbar-nav m-auto" :class="[{'hide': !IsShow}]">
                                    <li class="nav-item">
                                        <div @click.prevent="goSubject('news')" :class="['nav-li-font',{'selected':this.path.indexOf('/news') > -1 }]">
                                            {{ $t('navbar.menu_7') }}
                                        </div>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <div class="nav-li-font" @click.prevent="goAnchor('#smart-venue')">
                                            {{ $t('navbar.menu_1') }}
                                        </div>
                                    </li>

                                    <li class="nav-item">
                                        <div class="nav-li-font" @click.prevent="goAnchor('#application-service')">
                                            {{ $t('navbar.menu_6') }}
                                        </div>
                                    </li>

                                    <li class="nav-item">
                                        <div class="nav-li-font" @click.prevent="goAnchor('#living-space')">
                                            {{ $t('navbar.menu_4') }}
                                        </div>
                                    </li> -->

                                    
                                    <li class="nav-item">
                                        <div :class="['nav-li-font',{'selected':this.path.indexOf('/exhibition') > -1 }]" @click.prevent="goSubject('exhibition')">
                                            {{ $t('navbar.menu_6') }}
                                        </div>
                                    </li>
                                    
                                    <li class="nav-item">
                                        <div :class="['nav-li-font',{'selected':this.path.indexOf('/industry') > -1 }]" @click.prevent="goSubject('industry')">
                                            {{ $t('navbar.menu_5') }}
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <div :class="['nav-li-font',{'selected':this.path.indexOf('/smartvenue') > -1 }]" @click.prevent="goSubject('smartvenue')">
                                            {{ $t('navbar.menu_1') }}
                                        </div>
                                    </li>
                                </ul>
                                <div>
                                    <div v-if="!isSticky">
                                        <LangSelect />
                                    </div>
                                    <div v-else>
                                        <LangSelect />
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import LangSelect from './LangSelect'
    import SmoothScroll from 'smooth-scroll'
    export default {
        name: 'NavbarStyleTwo',
        components: {
            LangSelect
        },
        props: {
            IsShow: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isSticky: false,
                isActive: false,
                contents: [],
                show : false,
                path:""
            }
        },
        mounted() {
            
            const that = this;
            if(location.hash && this.$router.currentRoute.path == "/")
            {
                this.goAnchor(location.hash);
            }

            
            window.addEventListener('scroll', () => {
                let scrollPos = window.scrollY
                if (scrollPos >= 100) {
                    that.isSticky = true
                    that.isActive = true
                } else {
                    that.isSticky = false
                    that.isActive = false
                }
            });
            
            this.path = this.$router.currentRoute.path;
        },
        methods: {
            goAnchor(selector) {
                if( this.$router.currentRoute.path != "/")
                {
                     this.$router.push({ path: "/"+selector });    
                }else 
                {
                    this.show = false;
                    const scroll = new SmoothScroll();
                    const anchor = document.querySelector(selector);
                    scroll.animateScroll(anchor, null, {
                        //updateURL: false,
                        speed: 800,
                        offset:60

                    });
                }
            },

            goSubject(subject) {
                if (this.$route.path !=  subject) {
                    this.$router.push({ path: "/"+subject, name: subject });  
                }
            }
        }
    }
</script>

<style>
    .hide {
        display: none;
    }

    .nav-area .navbar-area.is-sticky {
        background-color: #000066;
    }

    .nav-area .container-fluid {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    @media (min-width: 0px) and (max-width: 991px) {
        .mobile-nav-hidden {
            display: none;
        }
    }

    @media (min-width: 992px) {
        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
        }

        nav {
            padding: 0 !important;
        }

        .navbar-brand {
            margin-right: 0rem;
        }

        .split-li-icon {
            font-size: 25px;
            position: relative;
            height: 60px;
            display: flex;
            align-items: center;
            padding-right: 35px;
            padding-left: 35px;
            color: #dedbdb;
        }

        .scroll-container {
            position: relative;
        }

        .scroll-nav-menu {
            position: absolute;
            background-color: #ffffff;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 50px;
            border-bottom-left-radius: 10px;
            top: 0;
            right: 0;
            width: 70%;
        }

        .scroll-nav-menu a {
            color: #000000 !important;
            font-size: 20px !important;
        }

        .scroll-nav-menu a:hover {
            color: #2680FF !important;
        }

        .scroll-nav-logo-image {
            width: 200px;
            height: 100%;
        }

        .scroll-nav-logo {
            background-color: #ffffff;
            padding: 15px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            /* margin-right: 100px; */
            max-width: 10%;
        }

        .scroll-lang-block {
            position: absolute;
            right: 32.5px;
            top: 22.5px;
        }

        .not-active {
            display: none;
        }

        .is-active {
            display: none;
        }
    }

    .community-btn {
        margin: 0.5vw;
        font-size: 25px;
        color: #ffffff;
    }

    .nav-logo-image {
        height: 50px;
    }

    .nav-area .navbar-area .main-nav {
        border-bottom: 1px solid rgb(0 0 0 / 0%);
        background-color: rgba(17, 19, 45, 0);
    }

    .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
        width: 8.3rem;
    }

    .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item a:hover {
        color: #ffff00;
    }

    .main-nav nav .navbar-nav .nav-item .dropdown-menu li {
        text-align: center;
    }

    .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
        color: #212529 !important;
        font-size: 16px;
        background-color: #ffffff;
        border-radius: 0.25rem;
    }

    .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover {
        color: #2680ff !important;
        background-color: #f8f9fa;
    }

    .navbar-area.is-sticky .main-nav .navbar .container-fluid .mean-menu ul li a {
        color: #000000 !important;
    }
    .selected {
        color: #ffff00 !important;
    }


    @media only screen and (max-width: 992px) {
        .navbar-nav {
    flex-direction: column !important;
}
        .nav-area .navbar-area .main-nav {
            background-color: #000066 !important;
        }

        .nav-area .navbar-area .main-nav .navbar .navbar-collapse {
            height: auto;
            padding-bottom: 30px;
        }

        .navbar-toggler .icon-bar {
            width: 28px;
            transition: all 0.3s;
            background: #ffffff;
            height: 2px;
            display: block;
        }

        .main-nav nav ul {
            padding: 15px 0;
        }

        .nav-area .navbar-area .main-nav .navbar .navbar-nav .nav-item {
            padding: 5px 0;
        }

        .bounce-enter-active {
            animation: bounce-in 0.5s;
        }

        .bounce-leave-active {
            animation: bounce-in 1s reverse;
        }

        @keyframes bounce-in {
            0% {
                transform: scale(0);
            }

            50% {
                transform: scale(1.05);
            }

            100% {
                transform: scale(1);
            }
        }

        .nav-area .navbar-area.is-sticky .main-nav .navbar .container-fluid #navbarSupportedContent .navbar-nav .nav-item .nav-li-font {
            font-size: 18px;
            color: #ffffff;
            text-transform: capitalize;
            transition: all 0.5s;
            margin-left: 0;
            padding: 0 1.2rem;
            position: relative;
            letter-spacing: 2px;
            padding-bottom: 5px;
        }
    }

    .nav-area .navbar-area .main-nav .navbar .container-fluid #navbarSupportedContent .navbar-nav .nav-item .nav-li-font {
        font-size: 16px;
        color: #ffffff;
        text-transform: capitalize;
        transition: all 0.5s;
        margin-left: 0;
        padding: 0 1.2rem;
        position: relative;
        letter-spacing: 2px;
        padding-bottom: 5px;
        cursor: pointer;
        text-shadow: 0px 0 1px #0909097d;
        padding-left: 22px;
    }

    .nav-area .navbar-area .main-nav .navbar .container-fluid #navbarSupportedContent .navbar-nav .nav-item .nav-li-font:hover {
        color: #ffff00;
    }

    .nav-area .navbar-area .main-nav nav .navbar-nav .nav-item .dropdown-menu {
        -webkit-transition: all 0.25s;
        transition: all 0.25s;
    }
</style>