import { render, staticRenderFns } from "./NewsPostContent.vue?vue&type=template&id=a2624692&scoped=true&"
import script from "./NewsPostContent.vue?vue&type=script&lang=js&"
export * from "./NewsPostContent.vue?vue&type=script&lang=js&"
import style0 from "./NewsPostContent.vue?vue&type=style&index=0&id=a2624692&scoped=true&lang=css&"
import style1 from "./NewsPostContent.vue?vue&type=style&index=1&lang=css&"
import style2 from "assets/css/ckeditor.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2624692",
  null
  
)

export default component.exports