<template>
  <div class="partner-area">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("partner.title") }}</h2>
      </div>
      <div class="partner-group">
        <!--
            <div class="section-title">
                <h3 class="child-title">{{ $t('partner.section_title_1') }}</h3>
            </div>
            -->
        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-4">
            <div class="partner-security">
              <a href="https://www.compal-iot.com/" target="_blank">
                <img src="~assets/project_images/pic/partner_logo1.png" alt="Image" />
                <!-- <p>合作夥伴簡述說明合作夥伴簡述點圖片會連到該網站</p> -->
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="partner-security">
              <a href="https://www.fhnet.com.tw/" target="_blank">
                <img src="~assets/project_images/pic/partner_logo2.png" alt="Image" />
                <!-- <p>合作夥伴簡述說明合作夥伴簡述點圖片會連到該網站</p> -->
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="partner-security">
              <a href="https://www.lightmatrix3d.com/" target="_blank">
                <img src="~assets/project_images/pic/partner_logo3.png" alt="Image" />
                <!-- <p>合作夥伴簡述說明合作夥伴簡述點圖片會連到該網站</p> -->
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr style="width:60%;" />
      <!--
        <div class="partner-group">
            <div class="section-title">
                <h3 class="child-title">{{ $t('partner.section_title_2') }}</h3>
            </div>
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="partner-security">
                        <img src="~assets/images/partner/partner-6.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="partner-security">
                        <img src="~assets/images/partner/partner-7.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="partner-security">
                        <img src="~assets/images/partner/partner-8.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="partner-security">
                        <img src="~assets/images/partner/partner-9.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
        -->
    </div>
    <div class="container">
      <div class="partner-slider-six owl-carousel owl-theme">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-4">
            <div class="partner-security">
              <a href="https://www.aptg.com.tw/my/" target="_blank">
                <img src="~assets/project_images/pic/partner_logo4.png" alt="Image" />
                <!-- <p>合作夥伴簡述說明合作夥伴簡述點圖片會連到該網站</p> -->
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="partner-security">
              <a href="https://www.vive.com/tw/" target="_blank">
                <img src="~assets/project_images/pic/partner_logo5.png" alt="Image" />
                <!-- <p>合作夥伴簡述說明合作夥伴簡述點圖片會連到該網站</p> -->
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="partner-security">
              <a href="https://www.qualcomm.com/home" target="_blank">
                <img src="~assets/project_images/pic/partner_logo6.png" alt="Image" />
                <!-- <p>合作夥伴簡述說明合作夥伴簡述點圖片會連到該網站</p> -->
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partner",
  data() {
    return {};
  },
};
</script>

<style scoped>
.partner-area {
  margin-top: 10px;
  margin-bottom: 100px;
}

.partner-area .container {
  max-width: 1000px;
}

.ptb-64 {
  padding-top: 65px;
  padding-bottom: 45px;
}

.partner-area .section-title {
  margin: -12px auto 0;
  max-width: none;
}

.partner-area .child-title {
  border-bottom: 1px #eee solid;
  padding-bottom: 40px;
}

.partner-security {
  padding: 0 30px;
  transition: all 0.5s;
  text-align: center;
}
.partner-security a {
  display: block;
}
.child-title {
  color: #006;
  font-weight: 500;
}

.partner-security a p {
  text-align: left;
  color: #454545;
}
.partner-group {
  margin-bottom: 0px;
}

@media (max-width: 991px)    {

  .partner-area img {
      max-width: 360px;
      max-height: 160px;
  }

  hr {
    display: none;
}

}

</style>
