<template>
  <div class="blog-details-area pb-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-auto col-sm-8 col-12">
          <div class="blog-content-column">
            <div class="blog-content-area post-items-style2">
              <div class="tag border-top-0">
                <ul class="card-type">
                  <li v-for="(tags, tagIndex) in postData.tag" :key="tagIndex">
                    <p href="#">#{{ tags.tag_name }}</p>
                  </li>
                </ul>
              </div>
              <div class="post-title">
                <h3>
                  {{ postData.message_title }}
                </h3>

              </div>
              <div class="post-date">
                <p>
                  {{ postData.post_date }}
                </p>
              </div>
              <article class="post-item post-items">
                <!-- <div class="thumb">
                  <div v-if="postData.picture && postData.picture.length == 1">
                    <img v-for="(img, index) in postData.picture" :key="index" :src="img.path" class="d-block w-100" />
                  </div>

                  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" v-else>
                    <div class="carousel-inner">
                      <div :class="['carousel-item', { active: index === 0 }]" v-for="(img, index) in postData.picture"
                        :key="index">
                        <img :src="img.path" class="d-block w-100" />
                      </div>
                    </div>

                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only"></span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only"></span>
                    </a>
                  </div>

                  <div class="shape-line"></div>
                </div> -->

                <div class="article-icon d-flex mt-10">
                  <div class="social-icons article-share"></div>
                  <div class="social-icons article-tags text-end">
                    <a :href="facebook" target="_blank">
                      <i class="icofont icofont-facebook"></i>
                    </a>

                    <a :href="line" target="_blank">
                      <i class="icofont icofont-line"></i>
                    </a>

                    <a v-clipboard:copy="message" v-clipboard:success="onCopy" v-clipboard:error="onError">
                      <i class="icofont icofont-link"></i>
                    </a>
                  </div>
                </div>
              </article>

              <div class="post-content ck-content">
                <figure>
                  <figcaption class="aboutus-introduction-text" v-html="postData.message_content"></figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";

export default {
  name: "BlogDetails",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/announcement/message`,
      postData: [],
      windowHref: "",
      postID: this.$route.params.post_id,
      currentURL: window.location.href,
      message: window.location.href,
      language: ''
    };
  },
  mounted() {
    this.language = this.$i18n.locale;
    this.getPostData();
    this.windowHref = window.location.href;
  },
  methods: {

    async getPostData() {
      const api = this.apiUrl + `?announcement_message_id=${this.postID}&language_id=${this.language}&language_format=value`;

      await this.axios.get(api).then((result) => {

        //將zh-TW去掉，不然上面直接使用['zh-TW']會噴錯
        result.data.post_date = DateFormat(
          new Date(Date.parse(result.data.post_date.replace(/-/g, "/"))),
          "yyyy/mm/dd"
        );
        this.postData = result.data;
      });
    },
    onCopy: function () {
      this.$message({
        showClose: true,
        message: '網址已複製。',
        type: 'success'
      });
    },
    onError: function () {
      this.$message({
        showClose: true,
        message: '複製失敗。',
        type: 'error'
      });
    }
  },
  computed: {
    facebook() {
      return `https://www.facebook.com/sharer.php?u=${this.windowHref}&quote=我想分享文章`;
    },
    line() {
      return `https://social-plugins.line.me/lineit/share?url=${this.windowHref}`;
    },
  },
};
</script>

<style scoped>


.blog-details-area
  .blog-details-desc
  .article-footer
  .article-share
  .social
  li
  a {
  width: 35px;
  height: 35px;
}
.blog-details-area {
  color: #000066;
  font-size: 14px;
}

.blog-details-area {
  color: #000033;
}
.blog-details-area{
  margin-bottom: 10px;
}
.blog-details-area .blog-details-desc .article-footer {
  margin-top: 15px;
}

.blog-details-area .widget-area .widget_categories ul li {
  font-weight: normal;
}
.blog-details-area .widget-area .widget_categories ul li::before {
  background: #33ccff;
}

.icofont {
  margin-right: 5px;
  padding: 8px;
  background-color: #0cb7bb;
  border-radius: 50%;
  font-size: 19px;
  color: #fff;
  line-height: 33px;
  transition: all 0.5s;
}

.icofont:hover {
    background: #000066;
}
.post-title {
  margin-bottom: 31px;
}
.post-title h3 {
  color: #000066;
}
.article-icon {
  margin: 5px 0 15px 0;
  height: 48px;
  
}
.social-icons a {
  margin-right: 2px;
  height: 48px;
  bottom: 3px;
  position: relative;
  cursor: pointer;
}
.tag {
  overflow:auto;
  margin-top:25px;
  margin-bottom: 5px;
}
.tag p{
  color: #00CCCC;
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-right: 16px;
}
.card-type li {
  float: left;
}
.post-title {
  margin-bottom: 15px;
}
.post-title h3 {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.11em;
  color: #000000;
}
.post-date {
  margin-bottom: 20px;
}
.post-date p{
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  /* or 200% */

  text-align: justify;
  letter-spacing: 0.11em;

  /* #454545 */

  color: #454545;
}
.thumb img {
  min-height: 380px;
  object-fit: cover;
  margin-bottom: 20px;
}


</style>
<style>
figure.media {
    display: block;
}
.pt-30 {
  padding-top: 30px;
}
</style>
<style lang="css" src="assets/css/ckeditor.css"></style>