<template>
   <kinesis-container>
    
  <div id="xr-banner" class="banner-area xr-banner-area-three banner-area-three">
    
    <div class="d-table">
      <div class="">
        
        <div class="container">
          <div class="banner-text xronly">
            
           
            <kinesis-element :strength="strength">
              
            <img
              src="~assets/project_images/xr/00_banner_title.png"
              id="banner_title"
            />
          </kinesis-element>
          <div id="banner_qrcode">
          <a id="submit-link" href="https://kecc.kktix.cc/events/76131a63-copy-1" target="_blank">
            <img
              src="~assets/project_images/xr/00_banner_qrcode.png"
            />
          </a>
        </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xr-banner-shape-1">
      <!-- <video autoplay muted loop class="video-block">
        <source src="~assets/video/元宇宙會展應用服務-5G網站影片V2.mp4" type="video/mp4" />
      </video> -->
      <img src="~assets/project_images/xr/0_banner.png" class="video-block" />
    </div>
    <!-- <div class="mobile-banner-image">
            <div class="banner-shape-1">
                <img class="banner-image" src="~assets/project_images/pic/intex-banner.jpg" alt="Image">
            </div>
        </div> -->
  </div>
</kinesis-container>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      strength:40,
    };
  },
  mounted() {
    
    if (window.screen.width <= 780) {
    this.strength = 5;
    }
  },
  methods: {
    goAnchor(selector) {
      let element = document.querySelector(selector);
      let topOfElement = element.offsetTop - 10;
      console.log(topOfElement);
      let scrollToTop = window.setInterval(function () {
        var pos = window.pageYOffset;
        if (pos <= topOfElement) {
          window.scrollTo(0, pos + 20);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    },
  },
};
</script>

<style>

.menu-area {
  /*height: 76px;*/
  position: relative;
  z-index: 1;
}
#banner_title {
  margin-left: 220px;
  margin-top: 76px;
  width: 835px;
}
#banner_qrcode {
  margin-left: 0;
  margin-top: 140px;
  width: 485px;
}
.logo {
  width: 195px;
}

.menu {
  float: right;
}
.navbar-nav {
  flex-direction: row;
}
.nav-item {
  cursor: pointer;
}

.menu-area li {
  padding: 25px 28px;
}
.menu-area .logo {
  margin: 15px 45px;
}
.banner-area.banner-area-three .xr-banner-shape-1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}
@media only screen and (max-width: 767px) {
  .navbar-nav {
    flex-direction: column;
  }
  .menu-area li {
    padding: 10px 22px;
  }
}

.xr-banner-text-color {
  font-size: 48px !important;
  font-family: "Open Sans", Arial, sans-serif !important;
  font-weight: 700 !important;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
  margin-bottom: 20px !important;
  margin-top: 70px;
}
.banner-text hr {
  border: 1px solid #ffa940;
  width: 180px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.xr-banner-shape-1 img {
  mix-blend-mode: overlay;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100vw;
}

#tsparticles canvas {
  height: 1000px !important;
}
.xr-banner-area-three {
  height: 1275px !important;
}

.banner-area.banner-area-three .banner-text {
  margin-top: 62px;
}

.banner-image {
  object-fit: cover;
  height: 800px !important;
}

.banner-down-image {
  width: 50px !important;
  padding-bottom: 100px;
}

.banner-area.banner-area-three .xr-banner-shape-1 {
  -webkit-animation: none;
  animation: none;
  /* background-color: rgba(38, 128, 255, 1); */
}

.banner-btn-style:hover {
  cursor: pointer;
}

.banner-area .banner-text {
  min-width: 100%;
}
.banner-text.xronly {
  overflow: unset;
}
#canvas,
#text {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}

#text h1 {
  opacity: 0.9;
}

.banner-area.banner-area-three .xr-banner-shape-1 {
  z-index: 0;
  /*pointer-events: none;*/
}

.banner-area.banner-area-three {
  pointer-events: all;
  background: none;
}

.mobile-banner-image {
  display: none;
}

@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
  .xr-banner-shape-1 .video-block {
    width: 100% !important;
    height: auto !important;
    object-fit: unset !important;
    z-index: -100 !important;
  }

  .banner-area.banner-area-three {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .banner-text-color {
    font-size: 80px !important;
  }

  .banner-text-color span {
    font-size: 80px !important;
  }
  .banner-area.banner-area-three {
    background-image: url("~assets/project_images/xr/1_banner.png");
    background-size: cover;
  }
}

@media (max-width: 768px) {
  /*  Medium devices (tablets, 768px and down) */
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
  .mobile-banner-image {
    display: block;
  }
  #banner_title {
    margin-left: 0;
}


  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding-right: 30px;
    padding-left: 30px;
  }
}
</style>
<style scoped>
#submit-link {
  width: 650px;
}
#submit-link img{
  width: 650px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  #banner_title {
  margin-left: 50px !important;
  }
  
}
@media (max-width: 992px) {
  .banner-area.banner-area-three {
    background-image: url("~assets/project_images/xr/1_xr_banner.png");
    background-size: cover;
  }
  #xr-banner .xr-banner-shape-1 {
    display: none;
  }
  
  #banner_title, #banner_qrcode {
    margin-left: 0;
    
  }
  
}
@media (max-width: 530px) {
  #banner_qrcode {
    width: 400px;
  }
  #banner_qrcode {
    width: 400px;
  }
  #submit-link img{
  width: 500px;
}
  }
  @media (min-width: 902px) and(max-width: 992px) {
    #banner_title {
    margin-top: 55px;
    
  }
  }
  @media (min-width: 768px) and (max-width: 902) {
  #banner_title {
    margin-top: 90px;
  }
  
  
}
</style>