import VueRouter from 'vue-router'
//develop homeView
import DefaultHome from 'components/DefaultHome/KECC5G/Home/DefaultHomeView'
import ApplicationMain from  'components/DefaultHome/KECC5G/Application/ApplicationMain'
import IndustryMain from  'components/DefaultHome/KECC5G/Industry/IndustryMain'
import IndustryPostMain from  'components/DefaultHome/KECC5G/Industry/post/IndustryPostMain'
import IndustryShowGlbviewerMain from  'components/DefaultHome/KECC5G/Industry/post/Glbviewer'
import ExhibitionMain from  'components/DefaultHome/KECC5G/Exhibition/ExhibitionMain'
import NewsMain from  'components/DefaultHome/KECC5G/News/NewsMain'
import NewsPostMain from  'components/DefaultHome/KECC5G/News/post/NewsPostMain'
import Xr from  'components/DefaultHome/KECC5G/Xr/DefaultXrView'
import SmartvenueMain from  'components/DefaultHome/KECC5G/SmartVenue/SmartVenueMain'
//Link Event

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            name: 'home',
            path: '/',
            component: DefaultHome
        },
        {
            name: 'home',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?',
            component: DefaultHome
        },
        {
            name: 'application',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/application',
            component: ApplicationMain
        },
        {
            name: 'applicationJson',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/application/:jsonName',
            component: ApplicationMain
        },
        {
            name: 'applicationLangJson',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/application/:jsonName',
            component: ApplicationMain
        },
        {
            name: 'applicationLangJsonRowId',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/application/:jsonName/:rowId',
            component: ApplicationMain
        },
        {
            name: 'industry',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/industry',
            component: IndustryMain
        },
        {
            name: 'industryPost',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/industry/post/:post_id(\\d+)',
            component: IndustryPostMain
        },
        {
            name: 'industryShowGlbviewerMain',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/industry/post/glbviewer/:file_name',
            component: IndustryShowGlbviewerMain
        },
        {
            name: 'exhibition',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/exhibition/',
            component: ExhibitionMain
        },
        {
            name: 'exhibition',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/exhibition/:post_id',
            component: ExhibitionMain
        },
        {
            name: 'news',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/news/',
            component: NewsMain
        },
        {
            name: 'newsPost',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/news/post/:post_id',
            component: NewsPostMain
        },
        {
            name: 'smartvenue',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/smartvenue/',
            component: SmartvenueMain
        },
        {
            name: 'xr',
            path: '/:language([A-Za-z0-9]{2}-[A-Za-z0-9]{2})?/xr',
            component: Xr
        }

    ],
})
