<template>
    <div class="page-title-area bg-1 about-view">
        <div class="container-fluid visitor-nav-parents">
            <div class="page-title-content">
                <ul>
                    <li>
                        <router-link to="/">
                            {{ $t('home')}}
                        </router-link>
                    </li>
                    <li>{{ bannerTitle }}</li>
                </ul>
            </div>
            <div class="mid-div">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div>
                            <svg class="clip-svg hidden-sm-down" width="0" height="0">
                                <defs>
                                    <clipPath id="imgleft" clipPathUnits="objectBoundingBox">
                                        <polygon points="0 0, 1 0, 0.8 1, 0 1"></polygon>
                                    </clipPath>
                                    <clipPath id="imgmid" clipPathUnits="objectBoundingBox">
                                        <polygon points="0.2 0, 1 0, 0.8 1, 0 1"></polygon>
                                    </clipPath>
                                    <clipPath id="imgmid2" clipPathUnits="objectBoundingBox">
                                        <polygon points="0.2 0, 1 0, 0.8 1, 0 1"></polygon>
                                    </clipPath>
                                    <clipPath id="imgright" clipPathUnits="objectBoundingBox">
                                        <polygon points=".2 0, 1 0, 1 1, 0 1"></polygon>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BaseTitle',
        props: {
            bannerTitle: String,
            breadCrumb: [

            ],
            basicNavTitle: [

            ],
            basicNavContent: [

            ],
        },
    }
</script>

<style scoped>
    .page-title-area{
       padding-top: 130px;
       padding-bottom: 130px;
    }
    .page-title-area::before {
        background-size: cover;
        background-image: url("~assets/project_images/home/application-banner.jpg");
        background-position-y: bottom;
        -webkit-animation: none;
        animation: none;
    }

    .page-title-content ul li {
        font-size: 1.2rem;
    }

    .page-title-content ul li:last-child {
        color: #FFFF00;
    }


    .page-title-area .page-title-content ul li::before {
        content: "〉";
        position: absolute;
        top: 0px;
        right: 10px;
        background-color: transparent;
        width: 10px;
        height: 10px;
    }

    .page-title-area .page-title-content ul li:nth-child(1)::before {
        content: "〉";
        color: #FFFF00 !important;
    }


    /* xs - Extra small devices (portrait phones, less than 576px) */
    @media (max-width: 1200px) {
        .banner-text-color {
            font-size: 50px !important;
            line-height: 50px !important;
            color: #ffff00 !important;
            margin: 0 !important;
        }
    }

    @media (max-width: 991px) {
            .page-title-area[data-v-7564e76d] {
            padding-top: 100px;
            padding-bottom: 100px;
        }

    }

    /* sm - Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
        .banner-text-color {
            font-size: 60px !important;
            /*----by wei---*/
            line-height: 136px !important;
            /*----by wei---*/
            color: #ffff00 !important;
            margin: 0 auto !important;
            /* font-family: 'boxicons';----by wei--- */
            letter-spacing: 5px !important;
            /*----by wei---*/
        }
    }
    @media (max-width: 992px) {
        /* Large devices (desktops, 992px and down) */
        .page-title-area::before {
            background-position-y: 0px;
        }       
    }

    .mid-div {
        position: relative;
        overflow: hidden;
        background-color: #fff;
        z-index: 5;
        bottom: 0;
    }

    .clearfix::after {
        display: block;
        content: "";
        clear: both;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    .clip-svg {
        position: absolute;
    }

    .bt-row-threebox {
        width: 117%;
        height: 240px;
    }

    .mid-div {
        position: relative;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0);
    }

    .imageicon {
        z-index: 0;
        background-size: 100% 100%;
        transition: all 0.3s ease-in;
        border-top: none;
        background-color: #fff;
        padding: 0px;
        box-shadow: none;
    }

    .align-items-center {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .d-flex {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .press-nav-1,
    .press-nav-2,
    .press-nav-3,
    .press-nav-4 {
        background-color: transparent !important;
    }

    .press-nav-1:hover,
    .press-nav-2:hover,
    .press-nav-3:hover,
    .press-nav-4:hover {
        background-color: #ffff00 !important;
    }

    .press-nav-1:hover h2,
    .press-nav-2:hover h2,
    .press-nav-3:hover h2,
    .press-nav-4:hover h2 {
        color: #2680ff !important;
    }

    .press-nav-1:hover p,
    .press-nav-2:hover p,
    .press-nav-3:hover p,
    .press-nav-4:hover p {
        color: #2680ff !important;
    }

    .press-nav-1 {
        left: 12%;
        -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
    }

    .press-nav-2 {
        left: 7%;
        -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
    }

    .press-nav-3 {
        left: 2%;
        -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
    }

    .press-nav-4 {
        left: -12%;
        -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
    }

    #features-area {
        background-color: #eff4f5;
    }

    .bt-fourbox-descr {
        text-align: left;
        padding-left: 8vw !important;
        padding-bottom: 1.4vw !important;
        flex-direction: column;
        color: #FFFFFF;
    }

    .bt-fourbox-descr h2 {
        color: #FFFFFF !important;
        font-weight: 100;
        text-shadow: 0px 0px 2px #101010a6;
        /*----by wei---*/
        font-size: 2rem;
    }

    .bt-fourbox-descr p {
        text-align: left;
        line-height: 1.3;
        color: #FFFFFF !important;
        font-size: 1rem;
        width: 75%;
    }

    @media screen and (max-width:576px) {
        .bt-fourbox-descr h2 {
            font-size: 1.2rem;
        }

        .bt-fourbox-descr p {
            font-size: 0.7rem;
            padding-right: 0.2rem;
        }

        .imgright a .bt-fourbox-descr {
            padding-right: 0.5rem;
        }
    }

    @media only screen and (max-width: 767px) {
        .mid-div {
            display: none;
        }

    }

    .ogs-nav-is-active {
        background-color: #ffff00 !important;

    }

    .ogs-nav-is-active h2{
        color: #2680ff !important;
    }

    .ogs-nav-is-active p{
        color: #2680ff !important;
    }
</style>