<template>




    <div id="glb" v-if="fileCheck != ''">
        <View3D ref="view3d" :src="postData.path" class="d-block w-100 h-100 view3d-3by1"
             @ready="onloadFinish" />
        <div class="d-flex justify-content-center spinner">
            <div class="spinner-grow float text-secondary" role="status" v-if="loadFinish">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

    </div>


</template>
  
<script>
export default {
    name: "BlogDetails",
    metaInfo() {
        return {
            title: this.title,
        };
    },
    data() {
        return {
            fileName: this.$route.params.file_name,
            loadFinish: true,
            fileCheck: false,
            apiUrl: `${process.env.VUE_APP_API_URL}/resources/files`,
            fileData: [],
        };
    },
    mounted() {
        this.getPostData();
    },
    methods: {
        async getPostData() {
            const api = this.apiUrl + `?resource_file_id=${this.fileName}`;
            console.log('ff');
            await this.axios.get(api).then((result) => {
                //將zh-TW去掉，不然上面直接使用['zh-TW']會噴錯
                if (result.data.file_type == '.glb') {
                    this.fileCheck = true;
                } else {
                    this.errormsg();
                }
                this.postData = result.data;

            });
        },

        onloadFinish: function () {
            this.loadFinish = false;
        },
        getFileExtension(filename) {
            return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
        },
        errormsg() {
            this.$message({
                showClose: true,
                message: '檔案錯誤',
                type: 'error'
            });
        }
    },
    computed: {


    },

};
</script>
  
<style scoped>
.spinner {
    position: absolute;
    top:50%; 
    left:50%;
}

.spinner-grow {
    width: 5rem;
    height: 5rem;
}

#glb {
    position: relative;
    height: 100vh;
}

.view3d-wrapper {
    height: 410px;
}
</style>
<style lang="css" src="assets/css/ckeditor.css">

</style>