<template>

  <div class="blog-details-area pb-70">

    <div class="section-title">
      <h2>{{ $t('navbar.menu_1') }}</h2>

    </div>
    <div class="pb-65 ">
      <p class="col-lg-7 col-md-8 col-sm-9 aboutcontent" :class="[{'text-center': language == 'en-us'}]">{{ $t('about.content') }}</p>
    </div>
    <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab"
      img-width="1024" img-height="600" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd">
      <!-- Text slides with image -->
      <a :href="advertisement.url" :class="[{ 'noneEvent': advertisement.url == '' }]" target="_blank" v-for="advertisement in advertisements" :key="advertisement.advertisement_picture_id">
        <b-carousel-slide :img-src="advertisement.path"></b-carousel-slide>
      </a>
    </b-carousel>
    <div class="container">
      <!-- <div class="section-title">
        <h2>{{ $t("industry_application.title") }}</h2>
      </div> -->
      <section>


        <div class="section-title service" id="smartvenueservice">
          <h2>{{ content.smartVenue.service }}</h2>

        </div>
        <div class="
            row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3
            g-4
            mb-38
          ">

          <div class="col-lg-6 col-xl-6 postlist">
            <div class="card h-100 border-0">

              <div class="card-zoom mb-15">
                <img src="/img/solution-2.1a744431.jpg" class="card-img-top" />
              </div>
              <div class="card-body">
                <h3 class="card-title">{{ content.smartVenue.illustrateTitle1 }}</h3>

                <ul class="illustrateContent" :class="[{'text-left': language == 'en-us'}]">
                  <li>
                    {{ content.smartVenue.illustrateContent1_1 }}
                  </li>
                  <li>
                    {{ content.smartVenue.illustrateContent1_2 }}
                  </li>
                  <li>
                    {{ content.smartVenue.illustrateContent1_3 }}
                  </li>
                </ul>

              </div>

            </div>
          </div>
          <div class="col-lg-6 col-xl-6 postlist">
            <div class="card h-100 border-0">

              <div class="card-zoom mb-15">
                <img src="~assets/images/solution/solution-7.jpg" class="card-img-top" />
              </div>
              <div class="card-body">
                <h3 class="card-title">{{ content.smartVenue.illustrateTitle2 }}</h3>
                <ul class="illustrateContent" :class="[{'text-left': language == 'en-us'}]">
                  <li>
                    {{ content.smartVenue.illustrateContent2_1 }}
                  </li>
                  <li>
                    {{ content.smartVenue.illustrateContent2_2 }}
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="col-lg-6 col-xl-6 postlist">
            <div class="card h-100 border-0">

              <div class="card-zoom mb-15">
                <img src="~assets/images/solution/solution-4.jpg" class="card-img-top" />
              </div>
              <div class="card-body">
                <h3 class="card-title">{{ content.smartVenue.illustrateTitle3 }}</h3>
                <ul class="illustrateContent" :class="[{'text-left': language == 'en-us'}]">
                  <li>
                    {{ content.smartVenue.illustrateContent3_1 }}
                  </li>
                  <li>
                    {{ content.smartVenue.illustrateContent3_2 }}
                  </li>
                  <li>
                    {{ content.smartVenue.illustrateContent3_3 }}
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="col-lg-6 col-xl-6 postlist">
            <div class="card h-100 border-0">

              <div class="card-zoom mb-15">
                <img src="~assets/images/solution/solution-8.jpg" class="card-img-top" />
              </div>
              <div class="card-body">
                <h3 class="card-title">{{ content.smartVenue.illustrateTitle4 }}</h3>
                <ul class="illustrateContent" :class="[{'text-left': language == 'en-us'}]">
                  <li>
                    {{ content.smartVenue.illustrateContent4_1 }}
                  </li>
                  <li>
                    {{ content.smartVenue.illustrateContent4_2 }}
                  </li>
                </ul>
              </div>

            </div>
          </div>

        </div>
        <div class="row justify-content-center pb-65">
          <a href="https://www.kecc.com.tw/zh-tw/contact-us" class="default-btn contact " target="_blank">
            {{ $t('let_talk.botton') }} </a>
        </div>

        <div class="row justify-content-center">
          <h2 class="card-title faqtitle">{{ content.smartVenue.faq }}</h2>
        </div>

        <div v-for="(question, index) in content.smartVenue.question" :key="index" class="faq">
          <button type="button" class="col-sm-12 faq-content" data-toggle="collapse" :data-target="'#q' + index"
            aria-expanded="false" :aria-controls="'#q' + index">{{ question }}</button>
          <div :id="'q' + index" :class="['collapse', 'col-sm-12', { 'show': index == '0' }]">
            <p class="answer" v-html=" content.smartVenue.answer[index] ">  </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";
export default {
  name: "BlogDetails",
  metaInfo() {
    return {
      title: this.title,
      
    };
  },
  data() {
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/blog/post_list`,
      industryList: "",
      currentURL: window.location.href,
      content: [],
      slide: 0,
      hash: this.$route.hash,
      advertisementApiUrl: `${process.env.VUE_APP_API_URL}/advertisement/picture_list`,
      advertisements: [],
      language:''
    };
  },
  mounted() {
    this.getIndustryListData();
    this.language = this.$i18n.locale;
    this.getJsonData(this.language);
    this.getAdvertisement();
    /*const activity_event_id = this.$route.params.activity_event_id;
    
    CLE.addClickLinkCount(
      this,
      "event/" + activity_event_id,
      "activity",
      "activity_event_id",
      activity_event_id
    );*/
  },
  methods: {
    getIndustryListData() {
      const api = this.apiUrl;
      let industryJson = {
        blog_id: 4,
        page: 1,
        limit: 10,
        order_by: [{ field: "post_date", method: "DESC" }],
        filters: {
          tags: [],
        },
        image_width: 510,
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json"
        },
      }
      this.axios.post(api, industryJson, postConfig).then((result) => {
        for (let i = 0; i < result.data.list.length; i++) {
          result.data.list[i].post_date = DateFormat(
            new Date(Date.parse(result.data.list[i].post_date.replace(/-/g, "/"))),
            "yyyy/mm/dd"
          );
        }
        this.industryList = result.data.list;
      });
    },
    async getAdvertisement() {
      const api = this.advertisementApiUrl + `?advertisement_id=3&language_id=0`;
      await this.axios.get(api).then((result) => {
        this.advertisements = result.data;

      });
    },
    getJsonData(language = "zh-tw") {
      this.content = require(`./lang/${language}.json`);

    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },


  },
  watch: {
    content: function () {
      this.$nextTick(function () {
        if (this.hash != "") {
        //需要执行的方法
        var anchor = document.getElementById("smartvenueservice");
        // chrome
        document.body.scrollTop = anchor.offsetTop;
        // firefox
        document.documentElement.scrollTop = anchor.offsetTop;
        // safari
        window.pageYOffset = anchor.offsetTop;
        }
      });
    }
  }
};
</script>

<style scoped>
.card {
  background-color: transparent;
}

.postlist {
  margin-bottom: 45px;
  transition: all 0.5s;
}

.postlist:hover {
    /* background-color: #000066; */
    transform: translateY(-5px);
}

.postlist:hover p {
    color: #fff;
}

.pb-50 {
  padding-bottom: 50px;
}

.faq {
  margin-bottom: 1px;
}

.tag p {
  color: #00CCCC;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-right: 16px;
}

.illustrateContent {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* or 162% */

  text-align: justify;
}

.faqtitle {
  margin-bottom: 30px;

}

h2 {
  margin-bottom: 10px;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  letter-spacing: 0.11em;
  color: #000066;
}

h3 {
  /* H3 */

  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.11em;

  /* #000033 */

  color: #000033;
}

.card-body {
  padding: 0;
}

.card-body a {
  display: contents;
}


.card-type li {
  float: left;
}

.answer {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 20px 0px;
  color: #212121;
}

.contact {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}


.mb-15 {
  margin-bottom: 15px;
}

.card-img-top {
  min-height: 380px;
  object-fit: cover;
}

.card-zoom {
  overflow: hidden;
  height: 380px;
  border-radius: 5px;
}

.faq-content {
    background-color: #00cccc;
    text-align: left;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 15px 20px;
    border-radius: 5px;
    /* background-color: #33ffff; */
    background-color: #000066;
    color: #003;
    background-color: #00006600;
    border: 1px solid #000066;
    margin: 3px 0;
    letter-spacing: 0.08em;
}

.illustrateContent {
  list-style-type: disc;
  padding-left: 20px;
}

#carousel-1 a {
  display: block;
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}

.carousel-item img {
  height: 400px;
}

.section-title {

  margin-top: 65px;
  margin-bottom: 20px;
}

.service {
  margin-bottom: 25px;
}

.aboutcontent {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0 auto;
}

.pb-65 {
  padding-bottom: 65px;
}

@media (max-width: 1200px) {
  .card-zoom {
  height: 300px;
}
}

@media (max-width: 991px) {
  .card-zoom {
  height: 250px;
}
}

@media (max-width: 768px) {
  .card-zoom {
  height: 320px;
}
}

@media (max-width: 568px) {
  #carousel-1 .carousel-inner .carousel-item img {
    aspect-ratio: 1024 / 720;
    object-fit: cover;
  }
  
}
.noneEvent {
  pointer-events:none;
}
</style>
