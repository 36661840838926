<template>
  <div id="topic" class="xr-area" :class="[language == 'en-us'?'entopic':'']">
    <div class="container">
      <div class="section-title xronly">
        <h1>{{content.section1.title}}</h1>
      </div>
      <hr class="xrhr" />
      <div class="row section-content">
        <div class="col-lg-12" id="t-conteant">
          <h2>{{content.section1.description1}}</h2>
          <h2>{{content.section1.description2}}</h2>
          <p class="p1 pt-20 pb-30 text-center" v-html="content.section1.description3">
            

          </p>

          <h3 class="text-center">{{content.section1.description4}}</h3>
          <p class="p1 pt-5 intent text-center">
            {{content.section1.description5}}
          </p>
          <p class="p1 pt-5 pb-30 intent text-center">
            {{content.section1.description6}}
          </p>
          <h3 class="text-center">{{content.section1.description7}}</h3>
          <p class="p1 pt-5 intent text-center">
            {{content.section1.description8}}
          </p>
          <p class="p1 pt-5 pb-30 intent text-center">{{content.section1.description9}}
          </p>
        </div>

        <div class="col-lg-4  wow fadeInUp">
          <div class="tic" id="tic1">
            <img src="~assets/project_images/xr/01_icon_1.svg" class="topic_img" />

            <h3>{{content.section1.imageTitle1}}</h3>
            <p class="p1 pt-5">{{content.section1.imageDescription1_1}}</p>
            <p class="p1">{{content.section1.imageDescription1_2}}</p>
            <p class="p1">{{content.section1.imageDescription1_3}}</p>
          </div>
        </div>
        <div class="col-lg-4 text-center wow fadeInUp">
          <img src="~assets/project_images/xr/01_icon_2.svg" class="topic_img" />

          <h3>{{content.section1.imageTitle2}}</h3>
          <p class="p1 pt-5">{{content.section1.imageDescription2_1}}</p>
          <p class="p1">{{content.section1.imageDescription2_2}}</p>
          <p class="p1">{{content.section1.imageDescription2_3}}</p>
        </div>
        <div class="col-lg-4 wow fadeInUp">
          <div class="tic" id="tic3">
            <img src="~assets/project_images/xr/01_icon_3.svg" class="topic_img" />

            <h3>{{content.section1.imageTitle3}}</h3>
            <p class="p1 pt-5">{{content.section1.imageDescription3_1}}</p>
            <p class="p1">{{content.section1.imageDescription3_2}}</p>
            <p class="p1">{{content.section1.imageDescription3_3}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Array,
  },
  name: "topic",
  data() {
    return {
      language:'',
    };
  },
  mounted() {
    this.language = this.$i18n.locale;
  }
};
</script>

<style scoped>
.xr-area {
  margin-top: -180px;
}

.topic_img {
  width: 150px;
  height: 160px;
}

#topic {
  padding-top: 10px !important;
}

#topic img {
  margin-bottom: 40px;
}

.tic {
  width: 170px;
  text-align: center;
}

#tic1 {
  float: left;
  text-align: center;
}

#tic3 {
  float: right;
  text-align: center;
}

#t-conteant .p1,
#t-conteant .p2,
#t-conteant .p3 {
  /* line-height: 40px; */
  /* font-size: 16px; */
}

.p1,
.p2,
.p3 {
  margin-bottom: 0 !important;
}

.entopic h2 {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 30px;
}
.entopic p {
  font-family: Arial, Helvetica, sans-serif !important;
}
.entopic .tic {
  width: auto;
}
@media only screen and (max-width: 991px) {
  #tic1 {
    float: none;
    width: auto;
  }

  #tic3 {
    float: none;
    width: auto;
  }

  #t-conteant .p1,
  #t-conteant .p2,
  #t-conteant .p3 {
    line-height: unset;
  }
}

@media only screen and (min-width:768px) and (max-width: 991px) {
  .xr-area {
    margin-top: -290px;
  }
}

@media only screen and (max-width: 768px) {
  .xr-area {
    margin-top: -390px;
  }
}
</style>