<template>
    <div>
        <b-modal id="modal-news" size="lg" hide-header>
            <div class="modal-body">
                <div class="popup">
                    <h3>{{getNewsData[0]}}.{{getNewsData[1]}}.{{getNewsData[2]}}</h3>
                    <h4 class="modal-title text-center mb-3">{{NewsModelList.message_title}}</h4>
                </div>
                <div class="card mb-3">
                    <div class="card-body">
                        <p v-html="NewsModelList.message_content"></p>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <b-button variant="secondary" @click="toggleModal">{{$t('modal.close_button')}}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'NewsModal',
        props: {
            NewsModelList: [

            ],
        },
        computed: {
            getNewsData() {
                let date = this.NewsModelList.post_date.split('-')
                let year = date[0]
                let month = date[1]
                let day = date[2].split(' ')[0].substring(0,2)
                return [year, month, day]
            }
        },
        methods: {
            toggleModal() {
                this.$root.$emit('bv::toggle::modal', 'modal-news')
            },

        }
    }
</script>

<style scoped>
    .news-modal-title {
        font-size: 20px !important;
    }

    .btn-secondary {
        background-color: #6c757d;
    }

    .popup h2,h3 {
        color: #000066 !important
    }

    .popup h2 {
        text-align: center;
        font-weight: 100;
        font-size: 5.313em;
        letter-spacing: 3px;
        margin: 30px 0 0 0;
        line-height: 0.7em;
    }

    .popup h3 {
        text-align: center;
        font-weight: bold;
        font-size: 1.700em;
        margin: 10px;
    }
</style>