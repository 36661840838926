<template>
  <div class="performance-area bottom-image-text">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-6 align-items-start section-title">
          <h2 class="title text-center" aria-hidden="true" role="document">
            {{ $t("navbar.menu_5") }}
          </h2>
        </div>
      </div>

      <div class="single-security-wrap owl-carousel owl-theme industryCarousel">
        <carousel ref="my-carousel" :autplay="true" :loop="true" :paginationEnabled="true" :autoplaySpeed="true"
          :autoplayTimeout="500" :navigationEnabled="true" navigationNextLabel="" navigationPrevLabel=""
          :perPage="showPageNumber">
          <slide data-index="0" v-for="(item, index) in newsList" :key="index">
            
            <div class="single-blog-post">

              <div class="industryBox">
                <!-- <router-link :to="{ path: '/industry/post/' + item.blog_post_id }">
                  <img :src="item.path" />
                </router-link> -->
                <div class="ndustry-image" :class="[{ 'bkc': isMobileDevice }]" :style="{ backgroundImage: `url('${item.path}'` }"></div>
                <div :class="[{ 'overlay': !isMobileDevice }]"  >
                  <div class="hoverText">
                    
                    <div class="centerjustify">
                      <h2 class="hover-title">
                        {{item.post_title}}
                      </h2>
<router-link :to="{ path: '/industry/post/' + item.blog_post_id }" class="yellow-btn mt-30" id="morebutton">
                {{ $t("application.content_button") }}
              </router-link>
                    </div>
                  </div>
                  
                </div>
              </div>


            </div>

          </slide>
        </carousel>
      </div>

      <NewsModel v-if="waitOnCallModel" :NewsModelList="NewsModelList" />
    </div>
  </div>
</template>
  
<script>
import DateFormat from "dateformat";
import NewsModel from "components/DefaultHome/KECC5G/Common/NewsModal";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "HomeTextCarousel",
  components: {
    Carousel,
    Slide,
    NewsModel,
  },
  data() {
    return {
      newsList: null,
      showPageNumber: window.innerWidth >= 991 ? 4 : 1,
      NewsModelList: [],
      waitOnCallModel: false,
      apiUrl: `${process.env.VUE_APP_API_URL}/blog/`,
      isMobileDevice: false
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("resize", () => {
      that.showPageNumber = window.innerWidth >= 991 ? 4 : 1;
    });
    let api = `${this.apiUrl}post_list`;
    let industryJson = {
      blog_id: 4,
      page: 1,
      limit: 10,
      language_id: this.$i18n.locale,
      order_by: [{ field: "post_date", method: "DESC" }],
      filters: {
        tags: [],
      },
      image_width: 510,
    };
    let postConfig = {
      headers: {
        "Content-Type": "application/json"
      },
    }
    this.axios.post(api, industryJson, postConfig).then((response) => {
      for (let index in response.data["list"]) {
        response.data["list"][index].post_date = DateFormat(
          new Date(
            Date.parse(
              response.data["list"][index].post_date.replace(/-/g, "/")
            )
          ),
          "yyyy-mm-dd"
        );
      }
      this.newsList = response.data["list"];
    });
    this.mobileDevice();
  },
  methods: {
    async toIndustry(blog_id) {
      this.$router.push({ path: "/industry/post/" + blog_id });
    },
    mobileDevice(){
    var mobileDevices = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
    this.isMobileDevice=false
    for(var i=0;i<mobileDevices.length;i++){
        if(navigator.userAgent.match(mobileDevices[i])){
          this.isMobileDevice=true
        }
    }
    
}
  },
};
</script>
  
<style scoped>
.VueCarousel-navigation-button {
  position: absolute;
  top: 50%;
  box-sizing: border-box;
  color: #000;
  text-decoration: none;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
}


.VueCarousel-pagination {
  display: none;
}

.performance-area .container {
  max-width: 1400px;
}

.b-avatar-sm {
  width: 1rem;
  height: 1rem;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.news-title-color {
  font-size: 34px !important;
  font-weight: bold !important;
  color: #000033;
}

.news-more-style {
  color: #007fff;
}


.news-container {
  position: relative;
}

.news-images {
  display: block;
  width: auto;
  height: auto;
  min-height: 20vh !important;
  max-height: 20vh !important;
}

.news-images-mask {
  width: auto;
  height: auto;
  min-height: 35vh;
  max-height: 25vh;
  background-color: rgba(0, 0, 0, 1);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
  z-index: 9;
}

.news-image {
  display: block;
  width: auto;
  height: auto;
  min-height: 25vh;
  max-height: 25vh;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
}



.performance-area .single-security-wrap.owl-theme .VueCarousel-dot-container .VueCarousel-dot.VueCarousel-dot--active {
  background-image: linear-gradient(to bottom right, #007fff, #007fff);
  width: 20px !important;
  padding: 0 1rem;
}

.single-blog-post {
  cursor: default;
  padding-right: 0;
}
.single-blog-post:hover {
  transform: translateY(0px);
}

.btn-secondary {
  background-color: #d1d1d1;
}

.b-avatar {
  margin-right: 10px;
}

.performance-area::before {
  background-color: transparent;
}

.b-avatar {
  margin-top: 1% !important;
}

.VueCarousel {
  padding-left: 5px;
}

.industryCarousel {
  margin-top: 10px;
  margin-bottom: 35px;
}

.industryCarousel .VueCarousel-navigation-button.VueCarousel-navigation-prev {
  padding: 0px !important;
  margin-left: -10px !important;
}

.industryCarousel .VueCarousel-navigation-button.VueCarousel-navigation-next {
  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: -10px !important;
}

.performance-area .container {
  max-width: 1400px;
}

.bottom-image-text {
  padding-top: 5vh;
  /*background-color: #eff4f5 !important;*/
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: top;
}

.performance-area .single-security-wrap.owl-theme .VueCarousel-dot-container .VueCarousel-dot.VueCarousel-dot--active {
  background-image: linear-gradient(to bottom right, #007fff, #007fff);
  width: 20px !important;
}

.VueCarousel-slide:first-child .event-border {
  border-left: none;
}
.VueCarousel-slide {
  padding: 0 5px;
}
.text-uppercase {
  color: #000033;
  font-weight: bold;
}
.ndustry-image {
  background-blend-mode: multiply;
  background-size: 102%;
  transition: all .5s;
  background-position: center center;
  width: auto;
  height: auto;
  min-height: 225px;
  min-width: 331px;
  background-repeat: no-repeat;
}
.industryBox img {
       width: auto; 
    height: auto;
  min-height: 225px;
  min-width: 331px;
  object-fit: cover;
}

.industryBox:hover .ndustry-image{
  background-size: 110% auto;
  transition: all .5s;
}



.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  transition: 0.5s ease;
}
.industryBox:hover .overlay {
  opacity: 1;
  background-color: #000033;
  height: 100%;
}
.hover-title {
  color: white;
  font-size: 16px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  padding: 20px 10px 0 0;
  
}

.hover-content {
  color: white;
  position: absolute;
  bottom: 10px;
}

.hover-date {
  font-size: 22px;
  font-weight: bold;
}
.hoverText {
  padding: 30px 5px 15px 5px;
  width: 90%;
  height: 100%;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
}
.hoverText hr {
  border: 1px solid #cccc00;
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
}


.industryBox:hover .overlay{
    opacity: 1;
    background-color: #000033a1;
    height: 100%;
    cursor: pointer;
}

.overlay{
    height: 100%;
}

.hover-title{
    padding: 0 10px 0 0;
    /* text-align: center;*/ 
    line-height: 23px;
}

#morebutton {
    /*display: none;*/
}

.bkc {
  opacity: 1;
    background-color: #000033a1;
    height: 100%;
    cursor: pointer;
}
.centerjustify {
  position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
@media (max-width: 991px)
    {
      .VueCarousel-navigation-button.VueCarousel-navigation-prev {
        margin-left: 2px !important;
    }
    .VueCarousel-navigation-button.VueCarousel-navigation-next {
        margin-right: 3px !important;
    }

    .ndustry-image{
       /* min-height: 400px;*/
    }

    .hover-title{
    line-height: 35px;
    font-size: 25px;
    }

    .VueCarousel-slide{
        padding: 0;
    }



    }

    

</style>
  
