<template>
    <div>
        <b-modal id="modal-subscription" ref="my-modal" centered :title="this.$t('newsletter.title')">
            <div class="modal-body">
                <div class="container-fuild">
                    <div class="row align-items-center">
                        <div class="col-lg-12 text-center">
                            <img src="~assets/project_images/pic/subscribe.jpg" alt="image">
                            <b-form class="mt-3">
                                <b-form-group>
                                    <b-form-input :placeholder="this.$t('newsletter.content')" type="email" required id="email">
                                    </b-form-input>
                                </b-form-group>
                            </b-form>
                            <div class="text-right">
                                <b-button variant="primary" @click="sendRequestSubscription()" v-if="!sending">
                                    {{$t('newsletter.button')}}
                                </b-button>
                                <b-button variant="primary" disabled v-if="sending">
                                    <b-spinner small type="grow"></b-spinner>
                                    {{$t('newsletter.sending_message')}}...
                                </b-button>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'NewsModal',
        data() {
            return {
                apiUrl: `${process.env.VUE_APP_API_URL}/EPaper/benchmark_contact`,
                sending : false
            }
        },
        computed: {
            getNewsData() {
                let date = this.NewsModelList.post_date.split('-')
                let year = date[0]
                let month = date[1]
                let day = date[2].split(' ')[0].substring(0, 2)
                return [year, month, day]
            }
        },
        methods: {
            sendRequestSubscription() {
                if (!this.formVerify()) return;
                this.sending = true;
                let api = `${this.apiUrl}`;
                let postParams = {
                    "email": document.querySelector("#email").value,
                    "benchmark_list_id": "18766702",
                };
                let postConfig = {
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
                this.axios.post(api, postParams, postConfig).then((response) => {
                    this.sending = false;
                    if (parseInt(response['data']) == 1) {
                        this.$swal({
                            icon: 'success',
                            title: this.$t('newsletter.success'),
                        });
                        this.$refs['my-modal'].hide();
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: this.$t('newsletter.failure'),
                            text: this.$t('newsletter.failure_message'),
                        });
                    }
                })
            },
            formVerify() {
                const email = document.querySelector("#email").value;
                const emailRule =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!emailRule.test(email)) {
                    this.$swal({
                        icon: 'error',
                        title: 'Email格式錯誤',
                        text: '請輸入正確的Email格式，範例：test@gmail.com。',
                    });
                    return false;
                }
                return true
            }
        }
    }
</script>
<style>
.modal-content .modal-header{
    justify-content: center;
}
.modal-content .modal-header .modal-title{
    font-size: 25px;
    font-weight: bold;
}
</style>
<style scoped>
    .sub-img-style {
        /* background-color: #000066 !important; */
        background-size: cover;
        padding: 200px 0;
        background-color: rgb(242 245 255 / 60%);
        background-blend-mode: multiply;
        background-position: center;
    }
    .form-control:focus {
        border: 1px solid #06b1d8!important
    }
</style>