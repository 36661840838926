import Vue from "vue";
import VueRouter from "vue-router";
import VueCarousel from "vue-carousel";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import BackToTop from "vue-backtotop";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import App from "./App.vue";
import "./assets/custom.scss";
import "./assets/fonts/fontawesome-free-5.15.3/css/all.css";
import { router } from "./router";
import Particles from "particles.vue";
import VueAxios from "vue-axios";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import i18n from "./assets/plugins/i18n"; // 引入語言套件
import VueMeta from "vue-meta"; // meta SEO
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2'
import VueKinesis from 'vue-kinesis'

import View3D, { LoadingBar }  from "@egjs/vue-view3d";
import "@egjs/vue-view3d/css/view3d-bundle.min.css";
//請求預設帶入Authorization
axios.defaults.headers.get["Authorization"] = "Bearer test123";
axios.defaults.headers.post["Authorization"] = "Bearer test123";

Vue.use(VueRouter);
Vue.use(VueCarousel);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(BackToTop);
Vue.use(Particles);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueMeta);
Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.use(VueKinesis)

Vue.use(View3D);


if (process.env.VUE_APP_DEBUG == "TRUE") {
  Vue.config.debug = true;
  Vue.config.devtools = true;
} else {
  Vue.config.debug = false;
  Vue.config.devtools = false;
}
new Vue({
  router,
  i18n,
  View3D,
  LoadingBar,
  render: (h) => h(App),
}).$mount("#app");
