<template>
  <div class="blog-details-area pt-100 pb-50">
    <div class="container">
      <!-- <div class="section-title">
        <h2>{{ $t("industry_application.title") }}</h2>
      </div> -->
      <section>
        <div>
      </div>

        <div class="
            row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3
            g-4
            mb-38
          ">
          <div class="col-lg-6 col-xl-6 postlist" v-for="industryData in industryList" :key="industryData.blog_post_id">
            <div class="card h-100 border-0">
              <div class="card-zoom">
                <router-link :to="{ path: '/industry/post/' + industryData.blog_post_id }" class="more">
                  <img :src="industryData.path" class="card-img-top" />
                </router-link>
              </div>
              <div class="tag border-top-0">
                <ul class="card-type">
                  <li v-for="(tags, tagIndex) in industryData.tag" :key="tagIndex">
                    <p href="#">#{{ tags.tag_name }}</p>
                  </li>
                </ul>
              </div>

              <div class="card-body"  >
                <router-link :to="{ path: '/industry/post/' + industryData.blog_post_id }" class="more">
                  <h3 class="card-title" :class="[{ 'text-left': locale == 'en-us' }]">{{ industryData.post_title }}</h3>
                </router-link>
                <p class="card-text">
                  {{ industryData.post_introduction }}
                </p>
              </div>
              <div class="postdate">
                <span>{{ industryData.post_date }}</span>
                <router-link :to="{ path: '/industry/post/' + industryData.blog_post_id }" class="more">
                  {{ content.industry.more }}
                </router-link>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";

export default {
  name: "BlogDetails",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/blog/post_list`,
      industryList: "",
      currentURL: window.location.href,
      content: [],
      locale: this.$i18n.locale
    };
  },
  mounted() {
    this.getIndustryListData();
    const language = this.$i18n.locale;
    this.getJsonData(language);
    /*const activity_event_id = this.$route.params.activity_event_id;
    
    CLE.addClickLinkCount(
      this,
      "event/" + activity_event_id,
      "activity",
      "activity_event_id",
      activity_event_id
    );*/
  },
  methods: {
    getIndustryListData() {
      const api = this.apiUrl;
      let industryJson = {
        blog_id: 4,
        page: 1,
        limit: 10,
        language_id: this.$i18n.locale,
        order_by: [{ field: "post_date", method: "DESC" }],
        filters: {
          tags: [],
        },
        image_width: 510,
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json"
        },
      }
      this.axios.post(api, industryJson, postConfig).then((result) => {
        for (let i = 0; i < result.data.list.length; i++) {
          result.data.list[i].post_date = DateFormat(
            new Date(Date.parse(result.data.list[i].post_date.replace(/-/g, "/"))),
            "yyyy/mm/dd"
          );
        }
        this.industryList = result.data.list;
      });
    },
    goIndustry(blog_post_id) {
      this.$router.push(`${this.$route.path}/post/${blog_post_id}`);
    },
    getJsonData(language = "zh-tw") {
      this.content = require(`./lang/${language}.json`);

    },
    
  },
};
</script>


<style scoped>
.card {
  background-color: transparent;
}

.postlist {
  margin-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.tag p {
  color: #00CCCC;
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  margin-right: 16px;
}

.table td, .table th {
    padding: 0px;
}


.card-title {
  margin-bottom: 10px;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: 0.08em;
  color: #000000;
}

.postlist:hover .card-title{
  color: #00cccc;
}

.card-body {
  padding: 0;
}
.card-body a {
  display: contents;
}
.postdate {
  color: #04cccc;
    font-size: 13px;
    margin-top: 10px;
}
.card-type li {
  float: left;
}
.more {
  float: right;
  /* 備註文字 */

  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  /* or 200% */

  text-align: justify;
  letter-spacing: 0.11em;

  /* #00cccc */

  color: #00CCCC;
}

.postlist:hover .more{
  /*color: #000066;*/
}

.card-text {
  font-size: var(--font-size);
  margin-bottom: 5px;
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
  color: #454545;
}

.card-img-top {
  min-height: 380px;
  object-fit: cover;
}
.card-zoom {
  overflow: hidden;
  height: 380px;
  transition: all 0.3s;
  opacity: 1;
  border-radius: 5px;
}
.postlist:hover .card-zoom{
  opacity: 0.8;
  transform: translateY(-5px);
  transition: all 0.3s;
}
.view3d-wrapper {
  width: 100%;
  height: 100vh;
}

.card-type li {
    border-radius: 9px;
    border: 1px solid #00cccc;
    margin: 5px 5px 5px 0;
    padding: 0px 9px;
}

.tag {
    margin-top: 5px;
}
.tag p {
    margin-right: 0px;
}



</style>
