<template>
    <div>
        <NavbarStyleTwo />
        <Banner />
        <AboutVenue />
        <Carousel />
        <Industry />
        <!-- <SmartIndustry /> -->
        <Application />
        <LivingSpace />
        <!-- <Service /> -->
        <News />
        <Partner />
        <!-- <LetTalk /> -->
        <FooterTop />
        <BacktopButton />
    </div>
</template>

<script>
    import NavbarStyleTwo from 'components/DefaultHome/Layout/NavbarStyleTwo'
    import FooterTop from 'components/DefaultHome/Layout/FooterTop'
    import BacktopButton from 'components/DefaultHome/Layout/BacktopButton'
    import Banner from './Banner'
    import AboutVenue from './AboutVenue'
    import Carousel from './Carousel'
    import Industry from './Industry'
    //import SmartIndustry from './SmartIndustry'
    import Application from './Application'
    import LivingSpace from './LivingSpace'
    //import Service from './Service'
    import Partner from './Partner'
    import News from './News'
    //import LetTalk from './LetTalk'

    //DefaultHomeView language Packages


    export default {
        name: 'DefaultHomeView',
        components: {
            NavbarStyleTwo,
            Banner,
            AboutVenue,
            Carousel,
            Industry,
            //SmartIndustry,
            Application,
            LivingSpace,
            //Service,
            BacktopButton,
            News,
            Partner,
            //LetTalk,
            FooterTop,
        },
        mounted() {

        }
    }
</script>

<style>
    html,
    body {
        touch-action: manipulation;
    }
    p {
        font-size: 16px;
    }
    .pb-60 {
        padding-bottom: 60px;
    }

    .ptb-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .section-title h2 {
        color: #000066;
        font-weight: bold;
    }

    .section-content {
        word-wrap: break-word;
        line-height: 200%;
        text-align: justify;
        font-size: 16px;
    }

    .section-content div {
        margin: 0 0 2rem 0;
    }

    .default-btn {
        font-size: 16px;
        color: #ffff00;
        padding: 15px 30px;
        line-height: 1;
        transition: all 0.5s;
        text-transform: capitalize;
        position: relative;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        z-index: 1;
        background-color: #000066;
    }

    .default-btn::before,
    .default-btn::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #000066;
        z-index: -1;
        transition: all 0s;
    }

    .yellow-btn {
        font-size: 16px;
        color: #000066;
        padding: 15px 30px;
        line-height: 1;
        transition: all 0.5s;
        text-transform: capitalize;
        position: relative;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        z-index: 1;
        background-color: #ffff00;
    }

    .yellow-btn::before,
    .yellow-btn::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #ffff00;
        z-index: -1;
        transition: all 0s;
    }
    a{
        transition: all 0.3s;
    }


    a:hover {
        text-decoration: none;
        color: #000066;
        transition: all 0.3s;
    }


    .section-content p,
    .section-title h2  {
        letter-spacing: 0.05em;
    }

    .section-title {
        margin: 0 auto 35px;
    }




    .yellow-btn:hover {
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    }

    .yellow-btn {
        border: 1px solid #ffff00;
    }

    .blue-btn {
        padding: 15px 30px;
        line-height: 1;
        transition: all 0.5s;
        text-transform: capitalize;
        position: relative;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        z-index: 1;
        border: 1px solid #000066;
        background-color: transparent;
        color: #000066;
    }
    .blue-btn:hover {
        color: #fff;
        background-color: #000066;
        border: 1px solid #000066;
    }

    .faq-area .container {
    padding: 4% 7% 7% 5%;
}

h3 {
    font-size: 25px;
}

.h4, h4 {
    font-size: 22px;
}
.aboutus-introduction-text h4{
    padding-top: 15px;
}
.aboutus-introduction-text h4 ~ p {
    /* margin-left: 22px; */
    margin-top: 8px;
}

p {
    letter-spacing: 0.08em;
}

.page-title-area .page-title-content ul li a:hover {
    color: #33ffff;
}

</style>