<template>
  <div class="banner-area banner-area-three">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="banner-text">
            <p class="banner-text-color">
              <span>{{ $t("banner.large_title_1") }}</span
              >{{ $t("banner.large_title_2") }}<br />{{
                $t("banner.large_title_3")
              }}
            </p>
            <p class="banner-text2-color mt-4">
              {{ $t("banner.small_title") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-shape-1">
      <video autoplay muted loop class="video-block">
        <source src="~assets/video/元宇宙會展應用服務-5G網站影片V2.mp4" type="video/mp4" />
      </video>
    </div>
    <!-- <div class="mobile-banner-image">
            <div class="banner-shape-1">
                <img class="banner-image" src="~assets/project_images/pic/intex-banner.jpg" alt="Image">
            </div>
        </div> -->
  </div>
</template>

<script>
export default {
  name: "Banner",
  
  
  methods: {
    
    goAnchor(selector) {
      let element = document.querySelector(selector);
      let topOfElement = element.offsetTop - 100;
      console.log(topOfElement);
      let scrollToTop = window.setInterval(function() {
        var pos = window.pageYOffset;
        if (pos <= topOfElement) {
          window.scrollTo(0, pos + 20);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    },
  },
};
</script>

<style>
#tsparticles canvas {
  height: 1000px !important;
}
.banner-area.banner-area-three {
  height: 100vh;
}

.banner-area.banner-area-three .banner-text {
  margin-top: 80px;
  text-shadow: 0px 0px 2px #1919192e;
}

.banner-area .banner-text span {
    text-shadow: 0px 0px 1px #1919190d;
}

video {
  mix-blend-mode: overlay;
  max-height: 100% !important;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

.banner-image {
  object-fit: cover;
  height: 800px !important;
}

.banner-down-image {
  width: 50px !important;
  padding-bottom: 100px;
}

.banner-text {
  text-align: left !important;
  font-family: "Noto Sans TC";
  font-weight: bold;
}

.banner-text2-color {
  font-size: 24px !important;
  color: rgba(54, 249, 251) !important;
  font-weight: 500;
}

.banner-area.banner-area-three .banner-shape-1 {
  -webkit-animation: none;
  animation: none;
  /* background-color: rgba(38, 128, 255, 1); */
}

.banner-btn-style:hover {
  cursor: pointer;
}

.banner-area .banner-text {
  min-width: 100%;
}

#canvas,
#text {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}

#text {
  min-height: 100vh;
  width: 100vw;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  font-size: 8vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#text h1 {
  opacity: 0.9;
}

.banner-area.banner-area-three .banner-shape-1 {
  z-index: 0;
  pointer-events: none;
}

.banner-area.banner-area-three {
  pointer-events: none;
}

.mobile-banner-image {
  display: none;
}

.banner-text-color {
  font-size: 90px !important;
  /*----by wei---*/
  line-height: 120px !important;
  /*----by wei---*/
  color: #ffff00 !important;
  margin: 0 auto !important;
  font-family: "Noto Sans TC";
  /*----by wei---*/
  letter-spacing: 5px !important;
  /*----by wei---*/
}

.banner-text-color span {
  font-size: 90px !important;
  line-height: 120px !important;
  color: #ffff00 !important;
  margin: 0 auto !important;
  font-family: "Noto Sans TC";
  letter-spacing: 5px !important;
  -webkit-text-stroke: 1px #ffff00;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  color: #fff;
}

@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
  .video-block {
    width: 100% !important;
    height: 1300px !important;
    object-fit: cover !important;
    z-index: -100 !important;
  }

  .banner-area.banner-area-three {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .banner-text-color {
    font-size: 80px !important;
  }

  .banner-text-color span {
    font-size: 80px !important;
  }
  .banner-area.banner-area-three {
    background-image: url("~assets/project_images/home/home-banner-phone.jpg");
  }
}

@media (max-width: 768px) {
  /*  Medium devices (tablets, 768px and down) */
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
  .mobile-banner-image {
    display: block;
  }

  .video-block {
    display: none;
  }

  .banner-text-color {
    font-size: 50px !important;
    /*----by wei---*/
    line-height: 85px !important;
    /*----by wei---*/
    color: #ffff00 !important;
    margin: 0 auto !important;
    font-family: "Noto Sans TC";
    /*----by wei---*/
    letter-spacing: 5px !important;
    /*----by wei---*/
  }

  .banner-text-color span {
    font-size: 50px !important;
    line-height: 85px !important;
    color: #ffff00 !important;
    margin: 0 auto !important;
    font-family: "Noto Sans TC";
    letter-spacing: 5px !important;
    -webkit-text-stroke: 2px #ffff00;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    color: #fff;
  }

  .banner-area.banner-area-three .banner-text {
    margin-top: -40px;
  }
  .banner-area.banner-area-three {
    height: auto;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding-right: 30px;
    padding-left: 30px;
  }
}
</style>
