<template>
  <div id="application-service" class="application-area">
    <div class="container app-box">
      <div class="section-title">
        <h2>{{ $t("application.title") }} </h2>
      </div>
      <div id="appContent">
      <div v-for="(exhibitionData, index) in exhibitionList" :key="index">
        <div class="row m-0">
          <div class="col-lg-6 p-0" v-if="index % 2 == 1 || !isImgPosition">
            <div class="service-img" >
              <img :src="exhibitionData.path">
            </div>
          </div>
          <div class="col-lg-6 p-0">
            <div class="cybersecurity-content">
              <h3 class="mb-2">{{ exhibitionData.post_title }}</h3>
              <p>{{ exhibitionData.post_introduction }}</p>
              <router-link :to="{ path: '/exhibition/' + exhibitionData.blog_post_id }" class="blue-btn mt-30">
                {{ $t("application.content_button") }}
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 p-0"  v-if="index % 2 == 0 && isImgPosition">
            <div class="service-img">
              <img :src="exhibitionData.path">
            </div>
          </div>
        </div>
      </div>
    </div>
      
      
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeApplication",
  data() {
    return {
      isImgPosition: window.innerWidth >= 981 ? true : false,
      exhibitionList: "",
      listApiUrl: `${process.env.VUE_APP_API_URL}/blog/post_list`,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("resize", () => {
      that.isImgPosition = window.innerWidth >= 981 ? true : false;
    });
    this.getExhibitionListData();
  },
  methods: {
    getExhibitionListData() {
      const api = this.listApiUrl;
      let exhibition = {
        blog_id: 5,
        page: 1,
        limit: 4,
        language_id: this.$i18n.locale,
        order_by: [{ field: "post_date", method: "DESC" }],
        filters: {
          tags: [],
        },
        image_width: 510,
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json"
        },
      }
      this.axios.post(api, exhibition, postConfig).then((result) => {

        this.exhibitionList = result.data.list;

      });
    },
  }
};
</script>

<style scoped>
#appContent {
  margin-top: 25px;
}

.service-img{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    max-height: 400px;
    overflow: hidden;
    width: 100%;
}

.cybersecurity-content {
  position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
    padding: 5% 10% 0 15%;
    margin-left: -10px;
}
.cybersecurity-content p{
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 20px;
}


@media only screen and (max-width: 1200px) {
    .service-img{
      max-height: 340px;
  }

  .cybersecurity-content{
    padding: 5% 1% 0 16%;
}

.cybersecurity-content .mt-30{
  margin-top: 10px;
}


}


@media only screen and (max-width: 992px) {
  
  .cybersecurity-content {
    padding: 7% 10%;
}
.cybersecurity-content p {
    padding-right: 0;
}
.service-img img {
    width: 100%;
}

.faq-area .container {
    padding: 4% 17% 7% 17%;
}

.hover-title {
    line-height: 35px;
}

.ndustry-image {
    min-height: 400px;
    min-width: 300px;

}

}


@media only screen and (max-width: 767px) {
  .cybersecurity-content {
    text-align: left;
    
  }
}
</style>
