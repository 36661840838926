<template>
  <div class="container-fluid navbar-main">
    <div class="row justify-content-center">
      <div class="navbar-title" v-for="(item, index) in navList" :key="index">
        <a href="#" @click.prevent="goAnchor('#' + item.id)">{{
          item.title
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import SmoothScroll from "smooth-scroll";

export default {
  name: "ApplicationNav",
  mounted() {
    const that = this;
    const jsonFile = this.$route.params.jsonName;
    const language = this.$i18n.locale;
    that.getJsonData(jsonFile, language);
  },
  updated() {
    if (location.hash) {
      this.goAnchor(location.hash);
    }
  },
  data() {
    return {
      navList: [],
    };
  },
  methods: {
    getJsonData(jsonFile, language = "zh-tw") {
      let json = require(`./Content/${jsonFile}.json`);
      this.navList = json[language];
    },
    goAnchor(selector) {
      const scroll = new SmoothScroll();
      const anchor = document.querySelector(selector);
      scroll.animateScroll(anchor, null, { updateURL: false, speed: 800 });
    },
  },
};
</script>

<style>
.navbar-main {
  background-color: #fff;
}
.navbar-title {
  padding: 15px 20px;
}
.navbar-title a {
  color: #000066;
  font-weight: bold;
  font-size: 18px;
}
.bg-color {
  background-color: #eff4f5;
}

.cd-secondary-nav {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cd-secondary-nav li:first-of-type {
  border-left: 0 solid #f4f4f4;
}

.cd-secondary-nav li {
  display: inline-block;
  flex-grow: 1;
  text-align: center;
  border-top: 0.1rem solid #f4f4f4;
  border-bottom: 0.1rem solid #f4f4f4;
  border-left: 0.1rem solid #f4f4f4;
}

.cd-secondary-nav a {
  display: block;
  color: #002d6e;
  font-weight: 900;
  line-height: 50px;
  padding: 0 1em;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: eurostile-condensed, Arial, sans-serif;
}

.cd-secondary-nav li:hover {
  background-color: #2680ff;
}

.cd-secondary-nav li:hover a {
  color: #fff;
}
</style>
