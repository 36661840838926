<template>
  <div id="xr">
    <Menu :content="content" />
    <Banner :content="content" />
    <Topic :content="content" />
    <Competition :content="content" />
    <!-- <Bonus /> -->
    <Submit :content="content" />
    <!-- <PropertyAndNote /> -->
    <MasterAndFood :content="content" />
    <Organizer :content="content" />
    <!-- <LetTalk /> -->
    <Footer :content="content" />
    <BacktopButton />
  </div>
</template>

<script>
import Menu from './Menu'
import Footer from "./Footer";
import BacktopButton from "components/DefaultHome/Layout/BacktopButton";
import Banner from "./Banner";
import Topic from "./Topic";
import Competition from "./Competition";
// import Bonus from "./Bonus";
import Submit from "./Submit";
// import PropertyAndNote from "./PropertyAndNote";
import MasterAndFood from "./MasterAndFood";

import Organizer from "./Organizer";

import { WOW } from "wowjs";
//import LetTalk from './LetTalk'

//DefaultHomeView language Packages

export default {
  name: "DefaultHomeView",
  components: {
    Menu,
    Banner,
    Topic,
    Competition,
    // Bonus,
    Submit,
    // PropertyAndNote,
    //Service,
    BacktopButton,
    MasterAndFood,
    Organizer,
    //LetTalk,
    Footer,
  },
  data() {
    return {
      language:'',
      content:[]
    };
  },
  mounted() {
    new WOW({
      live: false,
    }).init();
    this.language = this.$i18n.locale;
    this.getJsonData(this.language);
  },
  methods: {
    getJsonData(language = "zh-tw") {
      this.content = require(`./lang/${language}.json`);
    },
  },
  metaInfo: {
    title: "5G雲應用XR創作大賽",
    titleTemplate: "%s ｜高雄展覽館",
  },
};
</script>

<style>
html,
body {
  touch-action: manipulation;
}
h1, h2, h3, h4, h5, h6 {
  text-transform:none;
}
p {
  font-size: 16px;
}
.pb-60 {
  padding-bottom: 60px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-title h2 {
  color: #000066;
}

.section-content {
  word-wrap: break-word;
  line-height: 200%;
  text-align: justify;
  font-size: 16px;
}

.section-content div {
  margin: 0 0 2rem 0;
}

.default-btn {
  font-size: 16px;
  color: #ffff00;
  padding: 15px 30px;
  line-height: 1;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: #000066;
}

.default-btn::before,
.default-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #000066;
  z-index: -1;
  transition: all 0s;
}

.yellow-btn {
  font-size: 16px;
  color: #000066;
  padding: 15px 30px;
  line-height: 1;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: #ffff00;
}

.yellow-btn::before,
.yellow-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #ffff00;
  z-index: -1;
  transition: all 0s;
}

a:hover {
  text-decoration: none;
  color: #000066;
}
.section-title.xronly {
  margin: 0 auto 21px !important;
}
#xr {
  background: radial-gradient(ellipse 40% 80% at center, #bf529a, #656ad5);
}
#xr h1 {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  color: #fde53a;
}
#xr h2 {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #ee81a4;
  text-align: center;
  margin-bottom: 15px;
}
#xr h3 {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #fde53a;
  margin-bottom: 9px;
}
#xr h4 {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #fde53a;
}
#xr .p1 {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.7px;
}
#xr .p2 {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.7px;
}
#xr .p3 {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0.7px;
}
.xrhr {
  border: 1px solid #fde53a;
  width: 150px;
  margin-bottom: 34px;
  margin-top: 10px;
}
.xr-area {
  margin-top: 65px;
}
.xr-center {
  display: flex;
  align-items: center;
}
.pb-15 {
  padding-bottom: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pt-5 {
  padding-top: 5px !important;
}
.mt-30 {
  margin-top: 30px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.intent {
  text-indent: -20px;
padding-left: 20px;
}
.sign_up {
  width: 210px;
  height: 42px;
  background: #FDE53A;
  margin: 0 auto;
  transition: all 0.3s;
}
.sign_up:hover {
    background: #ee81a4;
    color: white;
}
#xr .note {
  font-size: 16px !important;
}
</style>