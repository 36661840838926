<template>
    <div id="living-space" class="container-fluid" style="background-color:#000066">
        <div class="container app-box">
        <div class="row align-items-center living-about">
            <div class="col-lg-3 LStitle">
                <h3 class="text-color-white" v-html="$t('living_space.title')" :class="[{'text-left': language == 'en-us'},{'text-center': language != 'en-us'}]"></h3>
                
            </div>
            <div class="col-lg-6 section-content">
                <p :class="[{'text-left': language == 'en-us'}]">{{ $t('living_space.content') }}</p>
            </div>
            <div class="col-lg-3 section-content">
                <router-link :to="{ path: '/smartvenue#smartvenueservice' }" class="yellow-btn mt-30" id="morebutton">
                 {{ $t("application.content_button") }}
                
              </router-link>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
  name: "living-space",
  
  data() {
    return {
        language:this.$i18n.locale
    };
  }
}
  </script>
<style scoped>
#living-space {
    padding: 30px 0;
    margin-top: 70px;
    margin-bottom: -35px;
}
#morebutton {
    position: relative;
    left: 30px;
    bottom: 8px;
}
    .section-content{
        padding-left: 60px;
    }
    .living-about .LStitle{
        color:#ffffff;
        /* border-right-style:solid; */
        border-width:0.5px;
        padding:5rem 0;
        padding-left: 15px;
        line-height: 60px;
    }
    .living-about div h3{
        color:#ffffff;
        display: inline-block;
        position: relative;
        /* bottom: 20px; */
    }
    .living-about div p{
        color:#ffffff;
    }

    @media (max-width: 991px)
    {
       .living-about .LStitle{
            border-width:0px;
        }
        .section-content{
            padding: 0 20px;
        }

        .living-about .LStitle {
            padding: 20px 0 10px 0;
            text-align: center;
        }

        .section-content{
            padding: 0 15%;
            text-align: center;
        }

        .living-about div p {
            text-align: left;
        }

        #morebutton {
            left: 0;
        }

        
    }
</style>