<template>
    <div class="bg-color">
        <Navbar />
        <Header :category="'visitors'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb" />
        <NewsPostContent />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/Layout/NavbarStyleTwo'
    import Header from 'components/DefaultHome/KECC5G/Common/Header'
    import NewsPostContent from './NewsPostContent'
    import FooterTop from 'components/DefaultHome/Layout/FooterTop'
    import FooterBottom from 'components/DefaultHome/Layout/BacktopButton'
    export default {
        name: 'NewsPostMain',
        components: {
            Navbar,
            Header,
            NewsPostContent,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: this.$t('navbar.menu_7'),
                borrowSpaceCrumb: [
                    {
                        name: this.$t('navbar.menu_7'),
                        path: "/news"
                    }
                ]
            }
        }
    }
</script>
