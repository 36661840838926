<template>
    <div>
        <transition name="fade">
            <keep-alive :exclude="['IndustryPostMain','IndustryMain','IndustryPostContent', 'NewsPostMain','ExhibitionMain']">


                <router-view></router-view>

            </keep-alive>
        </transition>
    </div>
</template>

<script>
import commonTw from 'components/DefaultHome/KECC5G/Common/lang/zh-tw.json'
import commonEn from 'components/DefaultHome/KECC5G/Common/lang/en-us.json'

export default {
    name: 'App',
    metaInfo() {
        return {
            title: `${this.$t("headTitle")}`,
            titleTemplate: (this.$route.name == "home") ? `` : `${this.$t("headTitle")} - %s`,
            htmlAttrs: {
                lang: `${(this.$i18n.locale).substr(0, 2)}`
            },
            meta: [
                {
                    'property': 'og:url',
                    'content': `${window.location.protocol}//${window.location.host}`,
                },
                {
                    'property': 'og:image',
                    'content': `${window.location.protocol}//${window.location.host}/no-picture-new.jpg`,
                },
                {
                    'property': 'og:description',
                    'content': this.$t("headDesc"),
                },
                {
                    'name': 'description',
                    'content': this.$t("headDesc"),
                },
                {
                    'name': 'viewport',
                    'content': 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0',
                },
                {
                    'name': 'format-detection',
                    'content': 'telephone=no',
                },
                {
                    'name': 'author',
                    'content': 'CTeam',
                },
                {
                    'name': 'copyright',
                    'content': '高雄展覽館',
                },
                {
                    'http-equiv': 'content-language',
                    'content': `${this.$i18n.locale}`,
                },
                {
                    'http-equiv': 'X-UA-Compatible',
                    'content': 'IE=edge,chrome=1',
                },
                {
                    'name': 'keywords',
                    'content': `${this.$t('keyWord')}`,
                }
            ],
            link: [
                {
                    'rel': 'alternate',
                    'href': `${window.location.protocol}//${window.location.host}${this.$i18n.bindLang(this.$route.path, 'zh-tw')}`,
                    'hreflang': 'zh-TW'
                },
                {
                    'rel': 'alternate',
                    'href': `${window.location.protocol}//${window.location.host}${this.$i18n.bindLang(this.$route.path, 'en-us')}`,
                    'hreflang': 'en-US'
                },
                {
                    'rel': 'alternate',
                    'href': `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
                    'hreflang': 'zh-TW'
                },
                {
                    'rel': 'alternate',
                    'href': `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
                    'hreflang': 'x-default'
                }
            ]
        }
    },
    created() {
        const lang_tw = Object.assign({}, commonTw, require('components/DefaultHome/KECC5G/Home/lang/zh-tw.json'));
        const lang_en = Object.assign({}, commonEn, require('components/DefaultHome/KECC5G/Home/lang/en-us.json'));
        this.$i18n.setLocaleMessage('zh-tw', lang_tw);
        this.$i18n.setLocaleMessage('en-us', lang_en);

        let currentLang = this.$route.params.language;

        if (currentLang != undefined && currentLang == 'en-us' || currentLang == 'zh-tw') {
            this.$i18n.locale = currentLang
        } else {
            const deviceLang = (navigator.browserLanguage || navigator.language).toLowerCase();
            const deviceLangReg = new RegExp("en", "gm");
            if (deviceLangReg.test(deviceLang)) {
                this.$i18n.locale = "en-us";
            }
        }

        this.$i18n.bindLang = function (path, language) {
            //統一去除掉現有的語系路徑
            //path = (path=="/") ? "" : path;
            if (currentLang != undefined) {
                path = path.replace(new RegExp("/" + currentLang, "gm"), "");
            }
            //沒特別指定就以當前語系為主
            let lang = currentLang;
            if (language != undefined) {
                lang = language;
            }
            return (lang == undefined) ? path : "/" + lang + path;
        }

    }

}
</script>