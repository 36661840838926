<template>
  <div :class="['menu-area', { 'menu-bk': scrollY > 0 }]" id="xr-menu">
    <nav class="navbar navbar-expand-md">
      <div class="menu-content container-fluid">
        <a href="https://5gsmartvenue.com.tw/">
          <img class="logo" src="~assets/project_images/xr/logo_KECC_header.svg" /></a>

        <b-navbar-toggle target="navbarSupportedContent">
          <span class="icon-bar top-bar"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </b-navbar-toggle>
        <b-collapse v-model="show" class="
                    collapse
                    navbar-collapse
                    mean-menu
                    text-center
                    justify-content-end
                  " id="navbarSupportedContent" is-nav>
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="nav-li-font" @click.prevent="goAnchor('#topic')">
                <p>{{ content.header.nav1 }}</p>
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-li-font" @click.prevent="goAnchor('#competition')">
                <p>{{ content.header.nav2 }}</p>
              </div>
            </li>

            <!-- <li class="nav-item">
                    <div
                      class="nav-li-font"
                      @click.prevent="goAnchor('#bonus')"
                    >
                      <p>獎勵辦法</p>
                    </div>
                  </li> -->

            <li class="nav-item">
              <div class="nav-li-font" @click.prevent="goAnchor('#submit')">
                <p>{{ content.header.nav3 }}</p>
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-li-font" @click.prevent="goAnchor('#master')">
                <p>{{ content.header.nav4 }}</p>
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-li-font" @click.prevent="goAnchor('#food')">
                <p>{{ content.header.nav5 }}</p>
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-li-font" @click.prevent="goAnchor('#organizer')">
                <p>{{ content.header.nav6 }}</p>
              </div>
            </li>
            <li class="nav-item">
              <div id="lang_box">
              <div class="lang-btn zhbtn">
                <div :class="['shape-ex5-block', { 'is-lang': lang == 'zh-tw' || lang == undefined }]"
                  @click="getChangeLang('zh-tw')">中</div>
              </div>
              <div class="lang-btn bg-white">
                <div :class="['shape-ex5-block', { 'is-lang': lang == 'en-us' }]" @click="getChangeLang('en-us')">EN</div>
              </div>
            </div>
            </li>
          </ul>
        </b-collapse>
      </div>
    </nav>
  </div>
</template>

<script>
import SmoothScroll from 'smooth-scroll'


export default {
  props: {
    content: Array,
  },
  name: "xr-menu",
  data() {
    return {
      scrollY: 0,
      show: false,
      lang: this.$route.params.language
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    goAnchor(selector) {
      this.show = false;
      const scroll = new SmoothScroll();
      const anchor = document.querySelector(selector);
      scroll.animateScroll(anchor, null, {
        //updateURL: false,
        speed: 300,
        offset: 100

      });
    },
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    getChangeLang(lang) {
      if (this.$route.params.language != undefined) {
        let path_str = `/${lang}/xr`;

        this.$router.push(path_str)
        window.location.reload()
      } else {
        this.$router.push(`/${lang}/xr`)
        window.location.reload()
      }

    }
  },
};
</script>

<style scoped>
#xr-menu {
  position: fixed;
  top: 0;
  width: 100%;
}

.menu-bk {
  background: #656AD5;
  animation: fadein 1s ease;
}

.menu {
  float: right;

}

.menu-area p {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.menu-area li {
  padding: 22px 28px;
}

.menu-area img {
  margin: 0 15px;
  width: 175px;
}

.zhbtn {
  float: left;
  margin-right: 15px;
}

.is-lang {
  width: 30px;
  height: 30px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffff00 !important;
  cursor: pointer;
  font-size: 12px;
}
#lang_box {
  margin: 0 auto;
    display: table;
}
@media only screen and (max-width: 767px) {

  .menu-area li {
    padding: 10px 22px;
  }


}

@media (max-width: 425px) {}

@keyframes fadein {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
}

@media (max-width: 880px) {
  .navbar-toggler.collapsed {
    display: block !important;
  }

  .navbar-toggler.not-collapsed {
    display: block !important;
  }

  #xr-menu {
    background-color: #656AD5;
  }

  .navbar-collapse {
    flex-basis: 100% !important;
    flex-grow: 1 !important;
    align-items: center;
  }

  .menu-content.container-fluid {
    flex-wrap: wrap;
  }
}

@media (max-width: 880px) {
  .navbar-header {
    float: none;
  }

  .navbar-left,
  .navbar-right {
    float: none !important;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }

  .navbar-nav>li {
    float: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-collapse.collapse.show {
    display: block !important;
  }
}

@media only screen and (min-width: 880px) and (max-width: 992px) {
  .navbar-nav {
    flex-direction: row !important;
  }
}</style>
