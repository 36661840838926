<template>
    <div id="submit" class="xr-area">
        <div class="container">
            <div class="section-title xronly">
                <h1>{{ content.section3.title }}</h1>
            </div>
            <hr class="xrhr">
            <div class="row section-content">
                <div class="col-lg-12" id="step1">
                    <h2>{{ content.section3.description1 }}</h2>
                </div>
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>11:00-11:30</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1 intent">{{ content.section3.list1_1 }}</p>
                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>11:30-12:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1 intent">{{ content.section3.list1_2 }}</p>
                    <p class="p1 intent">{{ content.section3.list1_3 }}</p>
                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>12:00-13:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list1_4 }}</p>
                </div>
                <hr class="submit-hr">


                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>13:00-14:30</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list1_5 }}</p>
                    <p class="p1">{{ content.section3.list1_6 }}</p>
                    <p class="p1">{{ content.section3.list1_7 }}</p>
                    <p class="p1">{{ content.section3.list1_8 }}</p>
                </div>

                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>14:30-15:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list1_9 }}</p>
                    <p class="p1">{{ content.section3.list1_10 }}</p>
                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>15:00-17:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list1_11 }}</p>
                    <p class="p1" v-html="content.section3.list1_12"></p>
                </div>

                <div class="col-lg-10 mb-0">

                </div>
                <hr class="submit-hr">
                <div class="col-lg-12" id="step2">
                    <h2>{{ content.section3.description2 }}</h2>
                </div>
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>11:00-11:30</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list2_2 }}</p>
                    <p class="p1 intent">{{ content.section3.list2_3 }}</p>
                </div>
                <hr class="submit-hr">


                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>11:30-12:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1" v-html="content.section3.list2_4"></p>

                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>12:00-13:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list2_5 }}</p>
                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>13:00-14:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1" v-html="content.section3.list2_6"></p>

                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>14:00-14:30</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list2_8 }}</p>
                    <p class="p1">{{ content.section3.list2_9 }}</p>
                </div>

                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>14:30-16:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1" v-html="content.section3.list2_10"></p>

                </div>
                <hr class="submit-hr">
                <div class="col-lg-12" id="step2">
                    <h2>{{ content.section3.description3 }}</h2>
                </div>

                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>11:00-11:30</h4>
                </div>





                <div class="col-lg-10 mb-0">
                    <p class="p1 intent">{{ content.section3.list3_1 }}</p>
                    <p class="p1 intent">{{ content.section3.list3_2 }}</p>
                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>11:30-12:00</h4>
                </div>

                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list3_3 }}</p>

                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>12:00-13:00</h4>
                </div>

                <div class="col-lg-10 mb-0">
                    <p class="p1"> {{ content.section3.list2_5 }}</p>

                </div>
                <hr class="submit-hr">
                <div class="col-lg-2 xr-center justify-content-center submit-step-title">
                    <h4>13:00-16:00</h4>
                </div>
                <div class="col-lg-10 mb-0">
                    <p class="p1">{{ content.section3.list3_4 }}</p>

                </div>




                <hr class="submit-hr">
                <button class="sign_up" @click="openNewPage">{{ content.btn }}</button>
                <!-- <div class="col-lg-12" id="note">
                    <h2>備註</h2>
                </div>
                <div class="col-lg-12">
                    <ol class="p2" id="note-content">
                    <li>作品不可有不當、中傷或誹謗、騷擾、辱罵、恐嚇、有害、庸俗、褻瀆、過度暴力、種族歧視、民族主義或其他不妥或具有攻擊性、淫穢、色情及露骨等內容。</li>
                    <li>除了可取得的大眾素材外，每一項作品參賽團隊皆須使用原創素材，或運用自有素材來製作單一作品，並且可在公開場合展示。參賽後，參賽團隊即應保證此作品為其原創作品，不會侵犯包含但不限於：任何人之商標、著作、專利等智慧財產權及/或任何人之肖像、隱私、姓名等人格權，並保證其他第三人不得在該作品主張任何權利。該作品亦不可違反或鼓勵他人違背任何現行的法律、法令、條例或法規。請勿複製個人喜愛的電影、書籍或照片，或是納入任何屬於第三方的素材、圖像、圖畫、音樂或註冊商標，或是整合您以外的第三方姓名、聲音、畫像或人物，除非您已取得所有必需之權利，允許您得以使用相同的內容與作品連結；作品內包含品牌名稱、註冊商標或公司標誌的參賽作品將被取消參賽的資格。</li>
                    <li>作品不應對任何人或動物造成不當的生命傷害。</li>
                    <li>作品以個人創作為限，嚴禁抄襲或盜用他人作品或著作參加徵選，若遭第三人主張侵權提出異議，除依法追繳參賽團隊原領之獎勵外(如無法繳回者將追繳獎勵價值之金額)，凡違反相關法規之法律責任一律由參賽團隊自行負責，概與主辦單位無涉，如有致損害於主辦單位或其他第三人者，參加者應負一切民刑事責任。</li>
                    <li>作品之著作權等相關權利已被買斷者，請勿參加本賽事，如涉有爭議，概由參賽團隊負責，與主辦單位無涉。</li>
                    <li>作品不可使用任何浮水印或可辨認參賽團隊之簽名/符號。</li>
                    <li>已公開發表之作品、曾參與其他比賽之作品、及侵害任何第三方權利之作品不得參賽。比賽結果公布之前，務必為個人作品保密。</li>
                    <li>作品不得包含可能損害比賽官網和影響比賽進行的病毒、毀損的檔案、木馬或其他任何惡意程式。</li>
                    </ol>

                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: Array,
    },
    name: 'submit',
    data() {
        return {

        }
    },
    methods: {
        openNewPage() {
            window.open('https://kecc.kktix.cc/events/76131a63-copy-1', '_blank')
        }
    }

}
</script>

<style scoped>
@media (max-width: 768px) {
    .btn-m-p {
        text-align: center;
    }
}

@media (max-width: 991px) {
    .p1 {
        text-align: center;
    }
}

.submit-hr {
    border: 1px solid #EE81A4;
    width: 100%;
    margin-bottom: 15px !important;
    margin-top: 24px;

}

#submit {
    padding-top: 75px;
}

#submit-link {
    color: white !important;
    text-decoration: underline;
}

#step1 {
    margin-bottom: 19px;
    margin-top: 5px;
}

#step2 {
    margin-top: 50px;
    margin-bottom: 23px;
}

#note {
    margin-top: 53px;
    margin-bottom: 27px;
}

.col-lg-10 .p1 {
    margin-bottom: -2px;
}

.note-content {
    line-height: 30px;
}

.submit-step-title {
    margin-bottom: 0;
}

button {
    margin-top: 60px;
}

@media (max-width: 993px) {
    .submit-step-title {
        margin-bottom: 20px;
    }
}</style>